import React from 'react'
import {Button} from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2,ServicesPf, Column3, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, Img, ImgWrap } from './ShortElements'
 
const Shortlist = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, description2, buttonLabel,
     img, alt, primary, dark, dark2}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={"online"}>
                <InfoWrapper>
                <Heading> Shortlisted CSO Candidates will participate<br></br> in an Ideathon before the Hackathon </Heading>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine> {topLine}</TopLine>
                                <Subtitle>As a first step of the “Hack to act” Hackathon
                                initiative, shortlisted CSO candidates who have applied with an idea for a digital solution, will
                                participate in an online Ideathon and capacity building workshop:<ServicesPf> “Digital civic space and the new technologies for democracy” 
                                at the end of May 2021.</ServicesPf>
                            </Subtitle>
                                
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={require('../../images/bulb.png').default} alt={"Idea for hackaton opportunity in medatarinean "} />
                            </ImgWrap>
                        </Column2>
                        <Column3>
                            <TextWrapper>
                                <TopLine> {topLine}</TopLine>
                                <Subtitle darkText={darkText}>
                                    During this workshop, participants will be skilled, trained and coached in order to further
                                    develop their project ideas before pitching them. Following a pitching session, <ServicesPf>up to 15 digital solutions will be selected</ServicesPf> to
                                    participate in the “Hack to act” Hackathon, in Tunis (Tunisia)</Subtitle>
                            </TextWrapper>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
                
            </InfoContainer>
           </>
    )
}

export default Shortlist
