
import React, {useState} from 'react'
import Navbar from '../../components/Navbar';
import Navbar2 from '../../components/Navbar2';
import Sidebar from '../../components/Sidebar';
import Faq from '../../components/Arabic/faqAR';
import Footer from '../../components/Footer';

const FrequentlyAskedQuestionsAR = () => {
    const [isOpen, setisOpen] = useState(false)

    const toggle = () =>{
        setisOpen(!isOpen)
    }
    return (
        <>
        <Navbar toggle={toggle}/>
        <Navbar2/>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Faq/>
        <Footer/>
        </>
    )
}

export default FrequentlyAskedQuestionsAR