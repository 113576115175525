import React from 'react';
import {SidebarContainer,NavBtnLink1, CloseIcon,Icon ,SidebarLinkR, SidebarBtnWrap, SidebarLink,SidebarMenu, SidebarWrapper} from './SidebarElements'


const Sidebar = ({isOpen,toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen}  onClick={toggle}>
            
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink href="https://meddialogue.eu/about-us/">About</SidebarLink>
                    <SidebarLink href="https://meddialogue.eu/vision-med-2030/">Vision Med 2030</SidebarLink>
                    <SidebarLink href="https://meddialogue.eu/capacity-development-page/">Capacity Development</SidebarLink>
                    <SidebarLink href="https://meddialogue.eu/med-dialogue-leaders/">Med Dialogue Leaders</SidebarLink>
                    <SidebarLink href="https://meddialogue.eu/advocacy-campaigns/">Advocacy Campaigns</SidebarLink>
                    <SidebarLink href="https://meddialogue.eu/advocacy-campaigns">Activism Days</SidebarLink>
                    <SidebarLink href="https://meddialogue.eu/media/">Media</SidebarLink>
                    <SidebarLink href="https://meddialogue.eu/gallery/">Gallery</SidebarLink>
                    <NavBtnLink1 href="https://meddialogue.eu/contact-us/">Contact Us</NavBtnLink1>
                </SidebarMenu>
                <SidebarBtnWrap>
                    
                </SidebarBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
};

export default Sidebar
