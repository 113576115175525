import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'

export const Button= styled(LinkR)`
font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
background: #0075B4;
align-items: center;
white-space: nowrap;
padding: 15px ;
color: #fff;
font-weight:500;
height: 45px;
font-size: 13px;
outline: none;
border: none;
cursor: pointer;
transition: all 0.8 ease-in-out;
text-decoration:none;

&:hover{
transition: all 0.8 ease-in-out;
transition: background-color 0.2s linear;
background:#fff;
padding: 13px 22px;
border: 2px solid #0075B4;
color: #0075B4;
}
    `
