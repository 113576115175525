import React, {useRef,useEffect,useState} from 'react';
import {HeroContainer1, HeroBg,Img,ImgBg,ImgWrap,Timer, TimerText,TimerWrapper1, TimerWrapper,StickyBarWrraper, StickyBar,StickyBarText, VideoBg, HeroContent, HeroH11, HeroH2, HeroP, HeroBtnWrapper, InfoRow, Column11, Column2,HeroH22 } from '../HeroSection/HeroSectionElements'
import {NavBtnLink1,NavBtnLink1197,NavBtn} from '../Navbar/NavbarElemnts'
import {TopLine,TextwMatfokch,TextwMatfokch1,ServicesWrapper1,NavBtnLink11} from './Topicele'
const FormCivicFR= () => {
const [hover, setHover] = useState(false)

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [timerDays1, setTimerDays1] = useState('00');
    const [timerHours1, setTimerHours1] = useState('00');
    const [timerMinutes1, setTimerMinutes1] = useState('00');
    const [timerSeconds1, setTimerSeconds1] = useState('00');
    let interval= useRef();

    const startTimer =() => {
        const countdownDate= new Date('May 06, 2021 12:00:00');

        interval=setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate- now;
            const days= Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours= Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000*  60));
            const seconds= Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                
            };
            
        }, 1000);
    };
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });
const onHover = () =>{
    setHover(!hover)
}

    return (
        <>
        <HeroContainer1 id="Hero">
            <HeroBg>
                <ImgBg src={require('../../images/herobackform1.png').default} alt={"Postulez pour hackathon du Méditerranée"} />
            </HeroBg>
            <StickyBarWrraper>
            <StickyBar>
                <StickyBarText to='/FormCivic'>EN</StickyBarText>
                <StickyBarText to='/FR/FormCivic'>FR</StickyBarText>
                <StickyBarText to='/AR/FormCivic'>عر</StickyBarText>
            </StickyBar>
            </StickyBarWrraper>
            <HeroContent>
                <InfoRow>
                    <Column11>
                    <HeroH11 to='/FR'>Hackathon <b>#HackToAct</b></HeroH11>
                        <HeroH2>
                        <b>Les inscriptions pour les activistes 
                        <br></br>de la société civile sont clôturées !</b>
                        </HeroH2>
                        
                    </Column11>
                    
                    <Timer>
                       <TimerWrapper1>
                            <TimerText>Date Limite<br></br><small>Activistes de la société civile</small></TimerText>
                        </TimerWrapper1> 
                        <TimerWrapper>
                            <TimerText>{timerDays}<br></br><small>Jours</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerHours}<br></br><small>Heures</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerMinutes}<br></br><small>Minutes</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerSeconds}<br></br><small>Secondes</small></TimerText>
                       </TimerWrapper> 
                    </Timer>
                    
                    
                </InfoRow>
                
            </HeroContent>
            
        </HeroContainer1>
        <ServicesWrapper1>
                <NavBtnLink11 to="/FR">
                        Hackathon #HackToAct, du 8 au 11 juillet 2021
                </NavBtnLink11>
                <NavBtnLink11 to="/FR/Guidelines">
                        Lire les lignes directrices des candidatures
                </NavBtnLink11>
                <NavBtnLink11 to="/FR/FormTech">
                        Formulaire pour les Développeurs IT & Graphistes
                </NavBtnLink11>
        </ServicesWrapper1>
            <br></br>
            <br></br>
            <br></br>
        </>
    )
}

export default FormCivicFR
