import styled from 'styled-components'


export const InfoContainer = styled.div`
        color:#000;       
       @media screen and (max-width: 768px) {
           padding: 150px 0;
       }
       @media screen and (max-width: 480px) {
              padding: 50px 0;
          }
       `

export const InfoWrapper = styled.div`
       display: grid;
       z-index: 1;
       height: 400px;
       width: 100%;
       max-wdith: 1100px;
       margin-right: auto;
       align-items:center;
       margin-left: auto;
       padding: 0 24px;
       justify-content: center;
       @media screen and (max-width: 768px) {
              height: 700px;
       }
`

export const InfoRow= styled.div`
       display: grid;
       grid-auto-columns: minmax(auto, 1fr);
       align-items: center;
       grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1 col3'`: `'col1 col2 col3'`)};

       @media screen and (max-width: 768px) {
           grid-template-areas: ${({imgStart}) => (imgStart ? `'col1 col1 col1' 'col2 col2 col2' 'col3 col3 col3'`: `'col1 col1 col1' 'col2 col2 col2' 'col3 col3 col3'`)};
       }
`

export const Column1 = styled.div`
       margin-bottom: 15px;
       padding: 0 15px;
       grid-area: col1;
`
export const Column2 = styled.div`
       margin-bottom: 15px;
       padding: 0 15px;
       grid-area: col2;
`
export const Column3 = styled.div`
       margin-bottom: 15px;
       padding: 0 15px;
       grid-area: col3;
`
export const TextWrapper = styled.div`
       max-width: 540px;
       padding-top: 0;
       padding-bottom: 60px;
`

export const TopLine = styled.p`
       color: #01bf71;
       font-size: 16px;
       line-height: 1.3;
       font-weight: 700;
       letter-spacing: 1.4px;
       text-transform: uppercase;
       margin-bottom: 16px;
       text-align:center;
`

export const Heading = styled.h1`
       color:#7AAFC6;
       font-size: 48px;
       line-height: 1.1;
       font-weight: bold;
       text-align:center;
       margin-bottom: 24px;

       @media screen and (max-width: 480px){
           font-size: 32px;
       }
`

export const Subtitle =styled.p`
       max-width: 440px;
       margin-bottom: 35px;
       text-align: justify;
       font-size: 18px;
       line-height: 24px;
       color: ${({darkText}) => (darkText ? '#010606':'#000')};
`

export const SubtitleAR =styled.p`
       max-width: 440px;
       margin-bottom: 35px;
       font-size: 18px;
       direction: rtl;
       text-align: justify;
       line-height: 24px;
       color: ${({darkText}) => (darkText ? '#010606':'#000')};
`
export const BtnWrap = styled.div`
       display: flex;
       justify-content: flex-start;
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
       width: 100%;
       margin: 0 0 10px 0;
       padding-right: 0;
`
export const ServicesPf = styled.b`
    color:#EE6A88;
    font-weight:bold;
`

export const ServicesPfAR = styled.b`
    color:#EE6A88;
    font-weight:bold;
   direction: rtl;
`