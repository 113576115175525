import React, {useState} from 'react'
import { Document, Page,ReactPDF } from 'react-pdf';
import file from './GuidelinesEN.pdf'
const GuidelineEN = () =>  {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

    return (
    <object data={file} type="application/pdf" width="100%" height="600vh">
       <p>Your web browser doesn't have a PDF plugin.
          Instead you can <a href={file}>Download the Guidelines.</a></p>
    </object>
    )
}

export default GuidelineEN
