import React, {useState} from 'react'
import Navbar from '../../components/Navbar';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/Footer';
import HeroSection from '../../components/French/HeroSectionFR';
import Services from '../../components/French/ServicesFR';
import InfoSection from '../../components/French/InfoSection1FR';
import InfoSection2 from '../../components/French/InfoSection2FR';
import Roadmap from '../../components/French/RoadMapFR';
import Topics from '../../components/French/TopicsFR';
import Shortlist from '../../components/French/ShortlistFR';
import LastSec from '../../components/French/LastSecFR';
import Sidebar from '../../components/Sidebar';


const LandingFR = () => {
    const [isOpen, setisOpen] = useState(false)

    const toggle = () =>{
        setisOpen(!isOpen)
    }
    
    return (
        <>
            
            <Navbar toggle={toggle}/>
            <Navbar2/>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <HeroSection/>
            <Services />
            <InfoSection2/>
            <Topics/>
            <InfoSection/>
            <Shortlist/>
            <Roadmap/>
            <LastSec/>
            <Footer/>
        </>
    )
}

export default LandingFR
