import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'


export const HeroContainer = styled.div`
       background: #c0c0c0c;
       display: flex;
       padding: 0 30px;
       height: 800px;
       margin-bottom:-80px;
       position: relative;
       z-index: 0; 

       
        @media screen and (max-width : 768px){
            height: 1000px;
            margin-top:200px;
            margin-bottom:0px;

        }
    
        @media screen and (max-width : 480px){
            height: 1200px;
        }
        @media screen and (min-width: 1600px) {
              align-items:center;
                  padding: 300px;
    }
`

export const HContainer = styled.div`
       background: #c0c0c0c;
       display: flex;
       padding: 0 45px;
       height: 800px;
       margin-bottom:-80px;
       position: relative;
       z-index: 0; 

       
        @media screen and (max-width : 768px){
            height: 1000px;
            margin-top:200px;
            margin-bottom:0px;

        }
    
        @media screen and (max-width : 480px){
            height: 1200px;
        }
        @media screen and (min-width: 1600px) {
              align-items:center;
                  padding: 300px;
    }
`

export  const HeroBg = styled.div`

    position: absolute;
    top: 0;
    right:0;
    bottom:0;
    left:0;
    width:100%;
    height:100%;
    overflow: hidden;
`

export const VideoBg = styled.img`
    
    @media screen and (min-width : 1000px){
        width:100%;
    }
    @media screen and (max-width : 768px){
        object-fit: cover;
    }
    @media screen and (max-width : 480px){
        object-fit: cover;
    }
`

export const HeroContent =styled.div`
    z-index : 6;
    max-width: 1200px;
    position: absolute;
    padding: 8px 20px;
    display : flex;
    flex-direction: column;
    align-items: center;
`

export const HeroH1 = styled.div`
    color: #fff;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size:48px;
    font-weight:bold;
    border-raduis:50px;
    text-align: left;

    @media screen and (max-width : 768px){
        font-size: 40px;
    }

    @media screen and (max-width : 480px){
        font-size: 32px;
    }
`

export const HeroP = styled.p`
    margin-top: 24px;
    color: #000;
    font-size: 24px;
    text-align: justify;
    max-width: 600px;

    @media screen and (max-width : 1000px){
        font-size: 21px;
    }
    @media screen and (max-width : 768px){
        font-size: 18px;
    }
    @media screen and (max-width : 480px){
        font-size: 14px;
    }

`

export const HeroBtnWrapper =styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`



export const InfoWrapper = styled.div`
       display: grid;
       z-index: 1;
       height: 400px;
       width: 100%;
       max-wdith: 1100px;
       margin-right: auto;
       align-items:center;
       margin-left: auto;
       margin:-18px 0;
       padding: 0 24px;
       justify-content: center;
       @media screen and (max-width: 768px) {
              height: 700px;
       }
`

export const InfoRow = styled.div`
       display: grid;
       grid-auto-columns: minmax(auto, 1fr);
       align-items: center;
       grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1 col3'`: `'col1 col2 col3'`)};

       @media screen and (max-width: 768px) {
           grid-template-areas: ${({imgStart}) => (imgStart ? `'col1 col1 col1' 'col2 col2 col2' 'col3 col3 col3'`: `'col1 col1 col1' 'col2 col2 col2' 'col3 col3 col3'`)};
       }
`

export const Column1 = styled.div`
       margin-bottom: 15px;
       padding: 0 15px;
       grid-area: col1;
`
export const Column2 = styled.div`
       margin-bottom: 15px;
       padding: 0 15px;
       grid-area: col2;
`
export const Column3 = styled.div`
       margin-bottom: 15px;
       padding: 0 15px;
       grid-area: col3;
`
export const TextWrapper = styled.div`
       max-width: 540px;
       padding-top: 0;
       padding-bottom: 60px;
`

export const TopLine = styled.p`
       color: #01bf71;
       font-size: 16px;
       line-height: 1.3;
       font-weight: 700;
       letter-spacing: 1.4px;
       text-transform: uppercase;
       margin-bottom: 16px;
`

export const Headingg = styled.h1`
       color:#fff;
       font-size: 48px;
       line-height: 1.1;
       font-weight: bold;
       text-align:center;
       @media screen and (max-width: 1000px){
        font-size: 40px;
        }
       @media screen and (max-width: 768px){
        font-size: 32px;
        }
       @media screen and (max-width: 480px){
           font-size: 24px;
       }
`

export const Subtitle =styled.p`
       font-family:"Segoe UI",sans-serif;
        text-align:center;
        margin-top:70px;
       font-size: 22px;
       font-weight:500;
       line-height: 24px;
       color: #fff;
       @media screen and (max-width : 1000px){
        margin-top:50px;
        font-size: 22px;
        }
        @media screen and (max-width : 768px){
            font-size: 20px;
        }
        @media screen and (max-width : 480px){
            font-size: 18px;
        }
`

export const SubtitleAR =styled.p`
       font-family:"Segoe UI",sans-serif;
        text-align:center;
        margin-top:70px;
       font-size: 22px;
       font-weight:500;
       line-height: 32px;
       direction:rtl;
       color: #fff;
       @media screen and (max-width : 1000px){
        margin-top:50px;
        font-size: 22px;
        }
        @media screen and (max-width : 768px){
            font-size: 20px;
        }
        @media screen and (max-width : 480px){
            margin-top:0;
            font-size: 18px;
        }
`
export const BtnWrap = styled.div`
       display: flex;
       justify-content: flex-start;
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
       width: 100%;
       margin: 0 0 10px 0;
       padding-right: 0;
`
export const Img1 = styled.img`
       width: 3%;
       margin: 0 10px 0px 0;
       padding-right: 0;
`
export const ServicesPf = styled.b`
    color:#EE6A88;
    font-weight:bold;
`

export const List= styled.ul`
    display: block;
    list-style-type: none;
    padding-inline-start: 40px;
    text-decoration:none;
    color:#000;
`
export const LiList= styled.li`
    color:#000;
    margin-left:-40px;
    padding-top:10px;
    text-align:justify;
    text-decoration:none;
    font-size: 16px;
    &:hover{
        color: #FFAA04;
        transition: 0.6s ease-out;
    }
`

export const ServicesCard = styled.div`
    height: auto;
    flex-direction: column;
    justify-content: center ;
    border-radius: 10px;
    text-align: center;
    background:#EE6A88;
    padding: 15px 17px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    position:relative;
    z-index:1;
    &:hover{
        transform scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    @media screen and (max-width: 480px){
        padding:15px;
    }
`

export const ServicesCard1 = styled.div`
    height: auto;
    flex-direction: column;
    justify-content: center ;
    border-radius: 10px;
    text-align: center;
    background:#EE6A88;
    padding: 15px 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    position:relative;
    z-index:1;
    &:hover{
        transform scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    @media screen and (max-width: 480px){
        padding:15px;
    }
`
export const ServicesIcon= styled.img`
    height: 250px;
    width:250px;
    margin-bottom: 10px;

`

export const ServicesH1 = styled.h1`
    font-size: 1.3rem;
    color:#fff;
    @media screen and (max-width: 1000px){
        font-size: 1.1rem;
        }
       @media screen and (max-width: 768px){
        font-size: 1rem;
        }
`


export const NavBtn= styled.nav`
    display: flex;
    align-items: center;
    margin-top:50px;
    
    @media screen and (max-width: 786px){
    }
`

export const NavBtnLink = styled(LinkR)`
    font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
    background: #0075B4;
    align-items: center;
    white-space: nowrap;
    padding: 14px 22px;
    color: #fff;
    font-weight:700;
    font-size: 22px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;

&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 13px 22px;
    border: 2px solid #0075B4;
    color: #0075B4;
}
@media screen and (max-width: 786px){
    margin: 15px;
    font-size: 18px;
}
@media screen and (max-width: 480px){
    margin: 15px;
    font-size: 14px;
}
`