import React, {useState} from 'react';
import {HContainer, HeroBg,Img,ImgWrap,ServicesCard,ServicesCard1,Subtitle,NavBtn,NavBtnLink,ServicesH1, VideoBg, HeroContent,InfoWrapper,Headingg,InfoRow,Column1,Column2,Column3 ,List,LiList,Img1, HeroH1, HeroP, HeroBtnWrapper } from '../Topics/Topicele'
import {Button} from '../ButtonElement'
const Topics = () => {
const [hover, setHover] = useState(false)

const onHover = () =>{
    setHover(!hover)
}

    return (
        <HContainer id="topics">
            <HeroBg>
                <VideoBg src={require('../../images/Groupe1.png').default} alt={"Solution Technologique pour le transparence et democratie"} />
            </HeroBg>
            <HeroContent>
            <InfoWrapper>
                <Headingg>Sur quelles thématiques* les Solutions Technologiques Proposées doivent-elles Porter ? </Headingg>
                    <InfoRow>
                        <Column1>
                        <ServicesCard>
                            <ServicesH1>Transparence et Responsabilité Publiques</ServicesH1><br></br>
                        </ServicesCard>
                        </Column1>
                        <Column2>
                        <ServicesCard>
                            <ServicesH1>Espace Numérique comme un Espace pour la Démocratie</ServicesH1><br></br>
                        </ServicesCard>
                        </Column2>
                        <Column3>
                        <ServicesCard1>
                            <ServicesH1>Construction Communautaire et Citoyenneté Active</ServicesH1><br></br>
                        </ServicesCard1>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
                <Subtitle>*Les solutions technologiques proposées peuvent couvrir un ou plusieurs des thématiques spécifiques comme : participation en ligne, transparence en ligne, Open data, mobilisation citoyenne, services de citoyens à citoyens, transparence et responsabilité des OSCs, etc.</Subtitle>
                <NavBtn>
                        <NavBtnLink to="/FR/Guidelines">
                                        Lire les lignes directrices des candidatures
                        </NavBtnLink>
                </NavBtn>
            </HeroContent>
        </HContainer>
    )
}

export default Topics
