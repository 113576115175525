import React from 'react';
import {HeroContainer, HeroBg,ServicesCard,Subtitle,NavBtn,NavBtnLink,ServicesH1, VideoBg, HeroContent,InfoWrapper,Headingg,InfoRow,Column1,Column2,Column3 } from './Topicele'
const Topics = () => {

    return (
        <HeroContainer id="topics">
            <HeroBg>
                <VideoBg src={require('../../images/Groupe1.png').default} alt={"Public Transparency & Accountability Digital Space as a Space for Democracy Community-building & Active Citizenship Hackathon"}/>
            </HeroBg>
            <HeroContent>
            <InfoWrapper>
                <Headingg>Which topics* may proposed<br></br> Digital Solutions deal with? </Headingg>
                    <InfoRow>
                        <Column1>
                        <ServicesCard>
                            <ServicesH1>Public Transparency & Accountability</ServicesH1><br></br>
                        </ServicesCard>
                        </Column1>
                        <Column2>
                        <ServicesCard>
                            <ServicesH1>Digital Space as a Space for Democracy</ServicesH1><br></br>
                        </ServicesCard>
                        </Column2>
                        <Column3>
                        <ServicesCard>
                            <ServicesH1>Community-building & Active Citizenship</ServicesH1><br></br>
                        </ServicesCard>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
                <Subtitle>* These topics may also cover several areas like e-participation, e-transparency, open data, citizen mobilisation, citizen-to-citizen services, civil society transparency & accountability, etc.</Subtitle>
                <NavBtn>
                        <NavBtnLink to="/Guidelines">
                                        Read the Guidelines for Applicants
                        </NavBtnLink>
                </NavBtn>
            </HeroContent>
        </HeroContainer>
    )
}

export default Topics
