import React, {useState} from 'react';
import {HContainer, HeroBg,Img,ImgWrap,ServicesCard,ServicesCard1,SubtitleAR,NavBtn,NavBtnLink,ServicesH1, VideoBg, HeroContent,InfoWrapper,Headingg,InfoRow,Column1,Column2,Column3 ,List,LiList,Img1, HeroH1, HeroP, HeroBtnWrapper } from '../Topics/Topicele'
import {Button} from '../ButtonElement'
const Topics = () => {
const [hover, setHover] = useState(false)

const onHover = () =>{
    setHover(!hover)
}

    return (
        <HContainer id="topics">
            <HeroBg>
                <VideoBg src={require('../../images/Groupe1.png').default} alt={"الحلول الرقمية المقترحة؟"} />
            </HeroBg>
            <HeroContent>
            <InfoWrapper>
                <Headingg>ما هي المواضيع * التي من الممكن أن تتناولها 
الحلول الرقمية المقترحة؟
</Headingg>
                    <InfoRow>
                        <Column1>
                        <ServicesCard>
                            <ServicesH1>الشفافية والمساءلة العامة</ServicesH1><br></br>
                        </ServicesCard>
                        </Column1>
                        <Column2>
                        <ServicesCard>
                            <ServicesH1>الفضاء الرقمي كمساحة للديمقراطية</ServicesH1><br></br>
                        </ServicesCard>
                        </Column2>
                        <Column3>
                        <ServicesCard1>
                            <ServicesH1>بناء المجتمع والمواطنة الفاعلة</ServicesH1><br></br>
                        </ServicesCard1>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
                <SubtitleAR>* يمكن أن تغطي الحلول التكنولوجية المقترحة واحدًا أو أكثر من الموضوعات المحددة مثل: المشاركة عبر الإنترنت ، والشفافية عبر الإنترنت ، والبيانات المفتوحة ، وتعبئة المواطنين ، والخدمات بين المواطنين ، والشفافية والمساءلة لمنظمات المجتمع المدني ، وما إلى ذلك.</SubtitleAR>
                <NavBtn>
                        <NavBtnLink to="/AR/Guidelines">
                                        اقرأ إرشادات التسجيل
                        </NavBtnLink>
                </NavBtn>
            </HeroContent>
        </HContainer>
    )
}

export default Topics
