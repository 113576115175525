import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'


export const Nav = styled.nav`
    background: #fff;
    hieght: 80px;
    padding-top: 8px;
    padding-bottom: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    top:0;    
    position:relative;
    z-index: 10;
    @media screen and (max-width: 960px) {
        transition : 0.8 all ease;
        }
    @media screen and (max-width: 780) {
            display : none;
            }
    ` ;



export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between; 
    align-items: center;
    height: 30px;
    background:#fff;
    z-index: 1;
    width: 100%;
    padding: 0 60px;
    max-width: 1100px;
    @media screen and (max-width: 786px){
        display: none;
    }
    `;

export const Container = styled.div`
    background:#fff;    
    width: 100%;
    padding-left:15px;
    padding-bottom:15px;
    padding-right:40px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
`;

export const Text= styled.div`
font-display: swap;
color:#0075B4;
font-size: 12.3px;
font-weight: 600;
padding-right: 15px;
padding-top: 12px;
outline: none;

margin-top:12px;
margin-left: 0px;
    text-decoration: none;
    &:hover{
        transition: all 0.8 ease-in-out;
        transition: color 0.4s linear;
        color: #06A5FA;
    }
`
export const Textlink= styled.a`
font-family:"alwyn-new-rounded-web",sans-serif;
text-decoration: none;
color:#0075B4;
font-size: 12.3px;
 font-weight: 600;
 outline: none;

padding: 5px;
padding-top: 11px;
margin-left: 0px;
&:hover{
    transition: all 0.8 ease-in-out;
    transition: color 0.4s linear;
    color: #06A5FA;
}
`
export const ServicesIcon= styled.img`

    height: 9px;
    width:9px;
    margin-left: 5px;

`

export const NavBtnLink1 = styled(LinkS)`
    Border-Radius: 50px;
    font-weight: bold;
    background: #0075B4;
    align-items: center;
    white-space: nowrap;
    padding: 8px 30px;
    color: #fff;
    height: 35px;
    width:130px;
    font-size: 13px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;

&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    color: #0075B4;
    padding: 8px 30px;
}
`

export const Sty = styled.div`
font-family:"alwyn-new-rounded-web",sans-serif;
 display: flex;
 align-items: center;
 outline: none;

 background:#fff;
 color:#0076B5;
 font-size: 12.3px;
 font-weight: 600;
 &:hover{
    transition: all 0.8 ease-in-out;
    transition: color 0.4s linear;
    color: #06A5FA;
    outline: none;

}
`