import React, {useState} from 'react'
import Navbar from '../../components/Navbar';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/Footer';
import HeroSection from '../../components/Arabic/HeroSectionAR';
import Services from '../../components/Arabic/ServicesAR';
import InfoSection from '../../components/Arabic/InfoSection1AR';
import InfoSection2 from '../../components/Arabic/InfoSection2AR';
import Roadmap from '../../components/Arabic/RoadMapAR';
import Topics from '../../components/Arabic/TopicsAR';
import Shortlist from '../../components/Arabic/ShortlistAR';
import LastSec from '../../components/Arabic/LastSecAR';
import Sidebar from '../../components/Sidebar';

const LandingAR = () => {
    const [isOpen, setisOpen] = useState(false)

    const toggle = () =>{
        setisOpen(!isOpen)
    }
    
    return (
        <>
            
            <Navbar toggle={toggle}/>
            <Navbar2/>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <HeroSection/>
            <Services />
            <InfoSection2/>
            <Topics/>
            <InfoSection/>
            <Shortlist/>
            <Roadmap/>
            <LastSec/>
            <Footer/>
        </>
    )
}

export default LandingAR
