import React,{Component,useState, useEffect} from 'react'
import { InfoContainer, InfoWrapper,TopLine , Heading, FormTitle } from './PersonalElements'
import {Formik, Form} from "formik";
import * as Yup from "yup";
import { Grid,Typography,Button } from '@material-ui/core';
import TextField from './FormsUIAR/Textfield/Textfield';
import TextField1 from './FormsUIAR/Textfield1/Textfield1';
import TextField2 from './FormsUIAR/Textfield2/Textfield2';
import TextField3 from './FormsUIAR/Textfield3/Textfield3';
import TextField4 from './FormsUIAR/Textfield4/Textfield4';
import Other from './FormsUIAR/Other/Other';
import Select from './FormsUIAR/Select/Select';
import countries from '../../Data/AR/countries.json';
import allcountries from '../../Data/AR/allcountries.json';
import Years from '../../Data/AR/Years.json';
import CurrentActivity from '../../Data/AR/CurrentActivity.json';
import Edevelopment from '../../Data/AR/Edevelopment.json';
import Experience from '../../Data/AR/Experience.json';
import Gender from '../../Data/AR/Gender.json';
import ICTtools from '../../Data/AR/ICTtools.json';
import ITemployment from '../../Data/AR/ITemployment.json';
import Itexpertise from '../../Data/AR/Itexpertise.json';
import Title from '../../Data/AR/Title.json';
import YesNo from '../../Data/AR/YesNo.json';
import Involvement from '../../Data/AR/Involvement.json';
import activities from '../../Data/AR/Activities.json';
import languages from '../../Data/AR/languages.json';
import DateTimePicker from './FormsUIAR/DataTimePicker/DataTimePicker';
import Checkbox from './FormsUIAR/Checkbox/Checkbox';
import Radio from './FormsUIAR/Radio/Radio';
import Radio2 from './FormsUIAR/Radio2/Radio2';
import Agree from './FormsUIAR/Agree/Agree';
import Buttonn from './FormsUIAR/Buttonn/Buttonn';
import { useForm } from "react-hook-form";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import firebase from '../../firebase';
import { v4 as uuid } from 'uuid';
import { Redirect } from 'react-router'


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:'center',
    border: '2px solid #01bf71',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const Tstyle = {
    alignItems: 'right',
    justifyContent: 'center',
    textAlign:'right',
    paddingTop:'16px',
    fontSize:"1rem",
    paddingRight:'12px',
  }
const Hstyle = {
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily:"Segoe UI",
    fontWeight:'600',
    padding:'14px 8px',
  }
const Hhstyle = {
    fontFamily:"Segoe UI",
    fontWeight:'600',
    padding:'14px 0px',
  }
const Bstyle = {
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight:'600',
    padding:'16px 8px',
  }
const Sstyle = {
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight:'600',
    fontSize:"14px",
    color:"#01bf71",
    padding:'16px 8px',
  }
  
const INITIAL_FORM_STATE ={
    title:'',
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    aptNo:'',
    street:'',
    district:'',
    city:'',
    postalCode:'',
    country:'',
    country1:'',
    gender:'',
    birthday:'',
    nationality:'',
    nationality1:'',
    englishReading:'',
    englishSpeaking:'',
    englishListening:'',
    arabicReading:'',
    arabicSpeaking:'',
    arabicListening:'',
    frenchReading:'',
    frenchSpeaking:'',
    frenchListening:'',
    organisation:'',
    organisationLocation:'',
    organisationLocation1:'',
    organisationActivities:'',
    currentWork:'',
    jobtitle:'',

    founder:'',
    linksWebsite:'',
    awarness:'',
    experience:'',
    successStories:'',
    previousPart:'',
    designThinking:'',
    designThinkingYear:'',
    nameICT:'',
    targetTown:'',
    descriptionICT:'',
    challengesICT:'',
    technicalICT:'',
    competitionICT:'',
    areasICT:'',
    areasICT1:'',
    innovationICT:'',
    timetable:'',
    firstKey:'',
    secondKey:'',
    thirdKey:'',
    reason:'',
    hackteam:'',
    hackteam1:'',
    specialCoaching:'',
    areasspecialCoaching:'',
    curriculumVitae:'',
    relfile:'',
    checkMe:''
};
const FORM_VALIDATION= Yup.object().shape({
    firstName: Yup.string()
        .required('إجباري'),
    lastName: Yup.string()
        .required('إجباري'),
    email: Yup.string()
        .email('يرجى إدخال البريد الإلكتروني صالح')
        .required('إجباري'),
    phone: Yup.number()
        .integer()
        .typeError("يرجى إدخال البريد الإلكتروني الصحيح")
        .required('إجباري'),
    aptNo: Yup.string()
        .required('إجباري'),
    street: Yup.string()
        .required('إجباري'),
    district: Yup.string()
        .required('إجباري'),
    city: Yup.string()
        .required('إجباري'),
    postalCode: Yup.number()
        .integer()
        .typeError('الرجاء إدخال الرمز البريدي صالح')
        .required('إجباري'),
    country: Yup.string().required('إجباري'),
    country1: Yup.string(),
    birthday: Yup.date().required('إجباري'),
    nationality: Yup.string().required('إجباري'),
    nationality1: Yup.string(),
    englishReading: Yup.string().required('إجباري'),
    englishSpeaking: Yup.string().required('إجباري'),
    englishListening: Yup.string().required('إجباري'),
    arabicReading: Yup.string().required('إجباري'),
    arabicSpeaking: Yup.string().required('إجباري'),
    arabicListening: Yup.string().required('إجباري'),
    frenchReading: Yup.string().required('إجباري'),
    frenchSpeaking: Yup.string().required('إجباري'),
    frenchListening: Yup.string().required('إجباري'),
    
    organisation: Yup.string()
        .max(1500,'من فضلك لا تتجاوز 1500 حرف'),
    organisationLocation: Yup.string().required('إجباري'),
    organisationLocation1: Yup.string(),
    organisationActivities: Yup.array(Yup.string().min(1,'إجباري')),
    organisationActivities1: Yup.string(),
    title: Yup
        .string().min(1,'إجباري')
        .required('إجباري'),
    gender: Yup
        .string()
        .required('إجباري'),
    currentWork : Yup
        .string().required('إجباري'),
    jobtitle: Yup.string(),
    checkMe: Yup
        .boolean()
        .oneOf([true], "عليك أن تقبل من أجل الاستمرار")
        .required('إجباري'),
    founder: Yup.string(),
    linksWebsite: Yup.string().max(1500,'من فضلك لا تتجاوز 1500 حرف'),
    awarness: Yup.string(),
    experience: Yup.string().required('إجباري'),
    successStories: Yup.string().max(1500,'من فضلك لا تتجاوز 1500 حرف').required('إجباري'),
    previousPart: Yup.string().max(1500,'من فضلك لا تتجاوز 1500 حرف').required('إجباري'),
    designThinking: Yup.string().required('إجباري'),
    nameICT: Yup.string().max(1500,'من فضلك لا تتجاوز 1500 حرف').required('إجباري'),
    targetTown: Yup.string().max(1500,'من فضلك لا تتجاوز 1500 حرف').required('إجباري'),
    descriptionICT: Yup.string().max(500,'من فضلك لا تتجاوز 100 كلمة').required('إجباري'),
    challengesICT: Yup.string().max(1500,'من فضلك لا تتجاوز 300 كلمة').required('إجباري'),
    technicalICT: Yup.string().max(1500,'من فضلك لا تتجاوز 300 كلمة').required('إجباري'),
    competitionICT: Yup.string().max(1500,'من فضلك لا تتجاوز 1500 حرف').required('إجباري'),
    areasICT: Yup.array(Yup.string()).max(3,"الرجاء تحديد فيما لا يزيد عن ثلاث مجالات").required('إجباري'),
    areasICT1: Yup.string(),
    innovationICT: Yup.string().max(1500,'من فضلك لا تتجاوز 1500 حرف').required('إجباري'),
    timetable: Yup.string().max(1500,'من فضلك لا تتجاوز 1500 حرف').required('إجباري'),
    firstKey: Yup.string().max(1500,'من فضلك لا تتجاوز 1500 حرف').required('إجباري'),
    secondKey: Yup.string().max(1500,'من فضلك لا تتجاوز 1500 حرف').required('إجباري'),
    thirdKey: Yup.string().max(1500,'من فضلك لا تتجاوز 1500 حرف').required('إجباري'),
    reason: Yup.string().max(1500,'من فضلك لا تتجاوز 1500 حرف').required('إجباري'),
    hackteam: Yup.array(Yup.string()).max(3,"يرجى تحديد ما لا يزيد عن ثلاثة خبراء").required('إجباري'),
    hackteam1: Yup.string(),
    specialCoaching: Yup.string().required('إجباري'),
    areasspecialCoaching: Yup.string(),
    designThinkingYear: Yup.string(),
    resume: Yup
        .mixed()
        .required("A file is required")
    
})


const PersonalForm = () => {
    const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [filename, setFilename] = React.useState("سيرة الذاتية");
  const [filename1, setFilename1] = React.useState("وثيقة أخرى");
const [filled, setfilled] = React.useState(false);
const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  
  const handleClose1 = () => {
    setOpen1(false);
    setfilled(true);
    
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
    return (
        <>
            <InfoContainer style={{direction:"rtl"}}>
            
                <InfoWrapper>
                    
                    <Formik initialValues={{
                        ...INITIAL_FORM_STATE
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={values =>{
                            console.log(values);
                            const file= values.resume;
                            const relevantFile= values.relevantFile;
                            const cvname= (values.firstName + ' ' +  values.resume.name);
                            let rvname='';
                            if (filename1!=='وثيقة أخرى'){
                                rvname= (values.firstName + ' ' +  values.relevantFile.name );
                            }
                            const CV = firebase.storage().ref("CivilCV").child(cvname);
                            if (rvname!==''){
                            const RF = firebase.storage().ref("CivilRelevantsFiles").child(rvname);
                            RF.put(relevantFile);
                            }
                            CV.put(file);
                            
                            values.curriculumVitae=cvname;
                            values.relfile=rvname;
                            let messageRef = firebase.database().ref('CivilActors').orderByKey().limitToLast
                            firebase.database().ref('CivilActors').push().set({
                            values
                            });
                            handleOpen1();
                        }}
                    >
                    {({ 
                        setFieldValue,
                        setFieldTouched,
                        values,
                        errors,
                        touched,
                    }) => (
                        <Form>
                        <FormTitle>1.  المعلومات الشخصية </FormTitle>
                            <Grid container spacing={2}>
                            
                                <Grid item md={4} sm={12}>
                                    <Radio
                                        name="title"
                                        label="Title"
                                        legend="اللقب"
                                        options={Title}
                                
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="firstName"
                                      label="الاسم الاول"
                                      
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="lastName"
                                      label="اللقب"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Radio
                                        name="gender"
                                        legend="الجنس"
                                        label="الجنس"
                                        options={Gender}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                      name="email"
                                      label="البريد الالكتروني"
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="phone"
                                      label="رقم الهاتف"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="nationality"
                                        label="الجنسية"
                                        options={countries}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <DateTimePicker
                                      name="birthday"
                                      label="تاريخ الميلاد"
                                      
                                    />
                                </Grid>{
                                 (values.nationality==="أخرى") ?
                                    (<Grid item xs={12}>
                                    <Grid  style={Hstyle} xs={12}>
                                        حدد بلد جنسيتك إذا لم يظهر في قائمة البلدان العشرة المستهدفة أعلاه
                                        </Grid>
                                        <Grid xs={12}>
                                        <Select
                                            name="nationality1"
                                            label=""
                                            options={allcountries}
                                        /></Grid>
                                    </Grid>
                                    ): ""
                                }
                                
                                 <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        عنوان الاقامة
                                    </Typography>
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="aptNo"
                                      label="رقم الشقة"
                                    />
                                </Grid>
                                
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="street"
                                      label="الشارع"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="district"
                                      label="المقاطعة"
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="city"
                                      label="المدينة / البلدة"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="postalCode"
                                      label="الرمز البريدى"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="country"
                                        label="الدولة"
                                        options={countries}
                                    />
                                </Grid>
                                 {(values.country==="أخرى") ?
                                    (<Grid item xs={12}>
                                    <Grid  style={Hstyle} xs={12}>
                                        حدد دولة إقامتك إذا لم تظهر في قائمة الدول العشر المستهدفة أعلاه
                                        </Grid>
                                        <Grid xs={12}>
                                        <Select
                                            name="country1"
                                            label=""
                                            options={allcountries}
                                        /></Grid>
                                    </Grid>
                                    ): ""
                                }
                                
                                {
                                 ((values.country==="أخرى") && (values.nationality==="أخرى")) ?
                                    (<Modal
                                        aria-labelledby="يرجى التحقق من شروط الأهلية"
                                        aria-describedby="يجب أن يكون المشارك مواطنًا و / أو مقيمًا في إحدى الدول التالية: الجزائر ، مصر ، الأردن ، لبنان ، ليبيا ، موريتانيا ، المغرب ، فلسطين ، سوريا ، تونس."
                                        className={classes.modal}
                                        open={open}
                                        onClose={handleClose}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                        timeout: 500,
                                        }}
                                    >
                                        <Fade in={open}>
                                        <div className={classes.paper}>
                                            <h2 id="transition-modal-title">يرجى التحقق من شروط الأهلية</h2>
                                            <br></br>
                                            <p id="transition-modal-description">:يجب أن يكون المشارك مواطنًا و / أو مقيمًا في إحدى البلدان التالية <br></br> الجزائر ، مصر ، الأردن ، لبنان ، ليبيا ، موريتانيا ، المغرب ، فلسطين ، سوريا ، تونس.</p>
                                        </div>
                                        </Fade>
                                    </Modal>): ""
                                }
{/*********** Language Skills *************/}
                                <Grid item xs={12}>
                                    
                                <FormTitle><br></br><br></br>2. مهارات اللغة  </FormTitle>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        الإنجليزية 
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="englishReading"
                                        label="قراءة"
                                        options={languages}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="englishSpeaking"
                                        label="تحدث"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="englishListening"
                                        label="الاستماع والفهم"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        الفرنسية
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="frenchReading"
                                        label="قراءة"
                                        options={languages}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="frenchSpeaking"
                                        label="تحدث"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="frenchListening"
                                        label="الاستماع والفهم"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        العربية
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="arabicReading"
                                        label="قراءة"
                                        options={languages}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="arabicSpeaking"
                                        label="تحدث"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="arabicListening"
                                        label="الاستماع والفهم"
                                        options={languages}
                                    />
                                </Grid>
                                
{/************* Relevent experience **************/}
                                <FormTitle><br></br><br></br>3. الخبرة ذات الصلة</FormTitle>
                                <Grid item xs={12}>
                                    <Radio2 
                                        legend="هل أنت متطوع أو تعمل حاليًا في حركة مدنية أو في أحد منظمات المجتمع المدني أو في مجتمع محلي؟"
                                        name="currentWork"
                                        options={CurrentActivity}
                                    /><br></br>
                                </Grid>
                                
                                {
                                 ((values.currentWork==="نعم ، أعمل بدوام كامل في إحدى منظمات المجتمع المدني") || (values.currentWork==="نعم ، أعمل بدوام جزئي في إحدى منظمات المجتمع المدني") || (values.currentWork==="نعم ، أنا متطوع مع إحدى الحركات المدنية أو منظمة مجتمع مدني أو في مجتمع محلي")) ?
                                    (<Grid xs={12} >
                                        <Grid  style={Hstyle} xs={12}>
                                            دخل اسم وعنوان المنظمة (المنظمات) المرتبط/  العامل بها:
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField2 
                                            name="organisation"
                                            multiline={true}
                                            rows={4}
                                            />
                                        </Grid><br></br>
                                        <Grid  style={Hstyle} xs={12}>
                                            مكان مؤسستك
                                        </Grid>
                                        <Grid item md={4} sm={12}>
                                            <Select 
                                                name="organisationLocation"
                                                label=""
                                                options={countries}
                                            />
                                        </Grid>
                                            {
                                            (values.organisationLocation==="أخرى") ?
                                                
                                                (<Grid item xs={12}>
                                                <Grid  style={Hstyle} xs={12}>
                                                    حدد موقع مؤسستك إذا لم يظهر في قائمة البلدان العشرة المستهدفة أعلاه
                                                    </Grid>
                                                    <Grid xs={12}>
                                                    <Select
                                                        name="organisationLocation1"
                                                        label="مكان مؤسستك"
                                                        options={allcountries}
                                                    /></Grid>
                                                </Grid>
                                                ): ""
                                            }
                                        <Grid item xs={12}>
                                            <Checkbox 
                                                name="organisationActivities"
                                                legend="مجالات أنشطة منظمتك (اختر خانة واحدة أو أكثر)"
                                                options={activities}
                                            />
                                        </Grid>
                                            {(values.organisationActivities.includes("أخرى")) ?
                                                (<Grid item md={4} sm={12}>
                                                <br></br>
                                                <Other
                                                name="organisationActivities1"
                                                label="يرجى تحديد"
                                                
                                                /><br></br>
                                            </Grid>): ""
                                            }<br></br>
                                        <Grid  style={Hstyle} xs={12}>
                                            منصبك / المسمى الوظيفي :
                                        </Grid>
                                        <Grid>
                                            <TextField2
                                            name="jobtitle"
                                            label=""
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Radio 
                                            name="founder"
                                            legend="هل أنت مؤسس المنظمة؟"
                                            options={YesNo}
                                            />
                                        </Grid>
                                        <Grid  style={Hstyle} xs={12}>
                                        أدخل اسم المنظمة والروابط (الموقع الالكتروني أو صفحات وسائل التواصل الاجتماعي) الخاصة بالمنظمة/ ات التي ترتبط بها (إن وجدت):
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField2 
                                            name="linksWebsite"
                                            multiline={true}
                                            rows={4}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Radio2
                                                legend="هل مجلس الإدارة أو إدارة مؤسستك على علم باهتمامك بهذا البرنامج؟" 
                                                name="awarness"
                                                options={YesNo}
                                            />
                                        </Grid>
                                </Grid>): 
                                (<Grid xs={12}>
                                        <Grid  style={Hstyle} xs={12}>
                                            مكان أنشطتك
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select 
                                                name="organisationLocation"
                                                label=""
                                                options={countries}
                                            />
                                        </Grid>
                                        {
                                            (values.organisationLocation==="أخرى") ?
                                                (<Grid item xs={12}>
                                                <Grid  style={Hstyle} xs={12}>
                                                     حدد موقع أنشطتك إذا لم يظهر في قائمة البلدان العشرة المستهدفة أعلاه
                                                    </Grid>
                                                    <Grid xs={12}>
                                                    <Select
                                                        name="organisationLocation1"
                                                        label="مكان أنشطتك"
                                                        options={allcountries}
                                                    /></Grid>
                                                </Grid>): ""
                                            }
                                        <Grid item xs={12}>
                                            <Checkbox 
                                                name="organisationActivities"
                                                legend="مجالات الأنشطة الخاصة بك (اختر خانة واحدة أو أكثر)"
                                                options={activities}
                                            />
                                        </Grid>
                                        {(values.organisationActivities.includes("أخرى")) ?
                                                (<Grid item md={4} sm={12}>
                                                <br></br>
                                                <Other
                                                name="organisationActivities1"
                                                label="يرجى تحديد"
                                                
                                                /><br></br>
                                            </Grid>): ""
                                            }
                                        <Grid  style={Hstyle} xs={12}>
                                        حدد الروابط (موقع الويب أو صفحات الوسائط الاجتماعية) المتعلقة بأنشطتك:
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField2 
                                            name="linksWebsite"
                                            multiline={true}
                                            rows={4}
                                            />
                                        </Grid>
                                </Grid>)
                                }
                                
                                
                                
                                
                                
                                <Grid item xs={12}>
                                    <Radio2
                                        legend="منذ متى وأنت تشارك في قضايا تعزيز الديمقراطية، حقوق الإنسان والمواطنة في مجتمعك؟" 
                                        name="experience"
                                        options={Experience}
                                    />
                                </Grid>
                                <Grid style={Hstyle} xs={12}>
                                    اكتب لنا قصتين نجاح كحد أقصى، ساهمت فيهما أو حققتهما في  مجال الديمقراطية / حقوق الإنسان / المواطنة
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField2 
                                      name="successStories"
                                      multiline={true}
                                      rows={4}
                                    />
                                </Grid>
                                <Grid style={Hstyle} xs={12}>
                                  هل سبق لك أن شاركت أو قدت مشروعًا لتصميم وتطوير أي من منتجات تكنولوجيا المعلومات؟ إذا كانت الإجابة بنعم، فهل يمكنك وصف المشروع والنتائج التي تم تحقيقها؟  
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField2 
                                      name="previousPart"
                                      multiline={true}
                                      rows={4}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Radio 
                                        name="designThinking"
                                        legend="هل سبق لك أن شاركت في تدريب على التفكير التصميمي؟ "
                                        options={YesNo}
                                    />
                                </Grid>
                                {
                                    (values.designThinking===("نعم")) ?
                                        (<Grid item xs={8}>
                                        <Grid  style={Hstyle} xs={12}>
                                            متى؟
                                            </Grid>
                                        <Select
                                            name="designThinkingYear"
                                            label=""
                                            options={Years}
                                        />
                                    </Grid>): ""
                                }

    {/************* 3.	ICT tool Description  **************/}
                                <FormTitle><br></br><br></br>4.	وصف الأدوات الرقمية المقترحة ITC tools   </FormTitle>
                                <Grid  style={Hstyle} xs={12}>
                                اسم الأداة الرقمية(ITC tool) المقترحة 
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="nameICT"
                                      multiline={true}
                                      rows={4}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    المدينة (المدن) / المقاطعة (المقاطعات) والبلد (البلدان) المستهدفة 
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="targetTown"
                                      multiline={true}
                                      rows={4}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                صف الأداة الرقميةITC tool المقترحة باختصار (فيما لايزيد عن 100 كلمة)
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="descriptionICT"
                                      multiline={true}
                                      rows={4}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                صِف هنا المشكلة الاجتماعية أو التحدي الذي يواجه عملية التنمية في بلدك والذي الأداة الرقميةITC tool المقترحة ، وذلك وفقًا للمواضيع الرئيسية لهذا الهاكاثون: (الشفافية العامة والمساءلة، الفضاء الرقمي كشكل من أشكال الفضاء المدني، وكذلك بناء المجتمع والمواطنة الفعالة). وما هو مدى معرفتك بالتحدي /المشكلة؟
                                صف التحديات التي سيتم مواجهتها وكيف ستساعد الأداة الرقميةITC tool المقترحة في حلها (فيما لايزيد عن 300 كلمة)
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="challengesICT"
                                      multiline={true}
                                      rows={4}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                صف التنفيذ التقني للأداة الرقمية الخاصة بك ITC tool: (فيما لا يزيد عن 300 كلمة)
صف هنا الوظيفة الرئيسية للأداة الرقمية الخاصة بك؟ ITC tool:، وكيف سيتفاعل المستخدمون معها / كيف سيستخدمونها
                                 </Grid>
                                <Grid item xs={12}>
                                    <TextField4 
                                      name="technicalICT"
                                      multiline={true}
                                      rows={4}
                                     
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    هل توجد أي أدوات أخرى لتكنولوجيا المعلومات والاتصالات /رقمية ITC tool تعالج نفس المشكلة في بلدك الأصلي أو في العالم؟
                                إذا كانت الإجابة بنعم، يرجى تقديم وصف موجز ، ورابط للحل وكيف يختلف اقتراحك عن الأدوات الرقمية المتواجدة حاليًا
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField4 
                                      name="competitionICT"
                                      multiline={true}
                                      rows={4}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox 
                                        name="areasICT"
                                        legend="برأيك ، ما هي مجالات التنمية في المجال الرقمي للمجتمع المدني والمواطنين التي تساهم فيها الأداة الرقميةITC tool الخاصة بك ؟ الرجاء تحديد فيما لا يزيد عن ثلاث مجالات"
                                        options={Edevelopment}
                                    />
                                </Grid>
                                {
                                 (values.areasICT.includes("أخرى")) ?
                                    (<Grid item xs={12}>
                                    <Other
                                      name="areasICT1"
                                      label="Veuillez préciser le(s) autre(s) thématique(s)"
                                    />
                                </Grid>): ""
                                }
                                <Grid style={Hstyle} xs={12}>
                                   حسب رأيك ، ما مدى الابتكار في الأداة الرقميةITC tool التي اقترحتها؟ ما الذي يجعلها فريدة من نوعها لاختيارها من بين جميع التطبيقات المقدمة؟
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField4 
                                      name="innovationICT"
                                      multiline={true}
                                      rows={4}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                  :الجدول الزمني الإرشادي
                                يوصى بتقدير المدة المحتملة لتنفيذ الحل من خلال مراعاة جميع العوامل ذات الصلة التي قد تؤثر على الجدول الزمني للتنفيذ مثل حالة Covid-19 والموارد التقنية والبشرية المقدمة.
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField4 
                                      name="timetable"
                                      multiline={true}
                                      rows={4}
                                    />
                                </Grid>

            {/************* 4.	Motivation  **************/}
                                <FormTitle><br></br><br></br>5.	الدافع </FormTitle>
                                <Grid  style={Hstyle} xs={12}>
                                صف ثلاثة أهداف شخصية رئيسية تريد تحقيقها من خلال مشاركتك في هذا النشاط:
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField4 
                                      name="firstKey"
                                      multiline={true}
                                      rows={3}
                                      label="الهدف الأول"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField2 
                                      name="secondKey"
                                      multiline={true}
                                      rows={3}
                                      label="الهدف الثاني"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField2 
                                      name="thirdKey"
                                      multiline={true}
                                      rows={3}
                                      label="الهدف الثالث"
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                   صف بإيجاز كيف ولماذا ستكون مشاركتك في هذا البرنامج مفيدة لك ولمشاركتك المستقبلية في مجتمعك / منظمتك:
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField2 
                                      name="reason"
                                      multiline={true}
                                      rows={3}
                                      label=""
                                    />
                                </Grid>
{/************* 5.	Hack team   **************/}
                                <FormTitle><br></br><br></br>6.	فريق البرمجة Hack team  </FormTitle>
                                <Grid  style={Sstyle} xs={12}>
                                   يرجى ملاحظة أنه قبل هاكاثون” Hack to Act" ، سيشارك 30 فاعلًا من المجتمع المدني المختارين من هذه الدعوة في ملتقى خلق الأفكار Ideathon لمدة 6 أيام، حيث سيتم تدريبهم وتوجيههم لتحسين الأدوات الرقمية المقترحة الخاصة بهم ITC tool. في نهاية ملتقى خلق الأفكار، سيتم اختيار 15 أداة رقمية ITC tool  من بين 30 شخصًا مشاركين في الهاكاثون.
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                  هذا يعني أنك ستتقدم باقتراح رقمية الخاصة بك ITC tool كخبير في الموضوع، ومن ثم تُشرك اثنين من المشاركين الآخرين (واحد منهم متخصص في تكنولوجيا المعلومات ومسؤول عن التنفيذ التقني بالاضافة إلى مصمم جرافيك واحد) كأعضاء في فريقك، وذلك عقب نجاح مشاركتك في ورشة عمل ملتقى خلق الأفكار Ideathon  مع صحة شرط اختيار الأداة الرقميةITC tool  الخاصة بكl.
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                   في هذا الصدد ، يرجى ملاحظة أنه إذا كنت من بين المشاركين في هاكاثون Hack to Act" ، فستعمل في فريق برمجة Hack team)) يحتوي على ثلاثة خبراء: فاعل متخصص واحد في المجتمع المدني (أنت) ، متخصص في تكنولوجيا المعلومات ( المبرمجين، وكاتبي الأكواد البرمجية، مطوري الواجهات الأمامية للمواقع، وما إلى ذلك) وكذلك مصمم جرافيك واحد.
                                 </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        name="hackteam"
                                        legend="يرجى تحديد ما لا يزيد عن ثلاثة خبراء تقنيين ستحتاجهم لتنفيذ اقتراح الأداة الرقميةITC tool الخاصة بك "
                                        label="Hack Team"
                                        options={ICTtools}
                                    />
                                </Grid>
                                {
                                    (values.hackteam.includes("أخرى")) ?
                                        (<Grid item xs={12}>
                                        <Other
                                        name="hackteam1"
                                        label="يرجى تحديد الخبير (الخبراء) التقنيين الآخرين"
                                        
                                        />
                                    </Grid>): ""
                                }
{/************* 6.	Additional information    **************/}
                                <Grid xs={12}><br></br><br></br><br></br>
                                    <FormTitle>7.	معلومات إضافية   </FormTitle>
                                </Grid>
                                <Grid item xs={12}>
                                    <Radio2
                                        name="specialCoaching"
                                        label=""
                                        legend="هل ستحتاج إلى تدريب أو إرشاد معين لتنفيذ الأداة الرقميةITC tool الخاصة بك إذا تم اختيارك من بين الفائزين الثلاثة؟"
                                        options={YesNo}
                                    />
                                </Grid>
                                {
                                    (values.specialCoaching==="نعم") ?
                                        (<Grid item xs={12}>
                                        <Grid style={Hstyle} xs={12}>
                                         اشرح في أي مجالات الخبرة ستحتاجها ولماذا تعتقد أنك بحاجة إليها؟
                                        </Grid>
                                        <Grid xs={12}><Other
                                        name="areasspecialCoaching"
                                        
                                        /></Grid>
                                    </Grid>): ""
                                }
                                <Grid  style={Bstyle} xs={5}>
                                    يرجى إمدادنا بسيرتك الذاتية الأحدث
                                </Grid>

                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        fullwidth
                                        >
                                        رفع ملف
                                        <input
                                            name="resume"
                                            type="file"
                                            hidden
                                            onChange={(event) => (setFieldValue('resume', event.target.files[0]),setFilename(event.target.files[0].name) )}
                                        />
                                        </Button>
                                        
                                        
                                        {/* ,console.log(values.curriculumVitae */}
                                </Grid>
                                <Grid md={4} sm={12} style={Hstyle}>{filename}</Grid>
                                <br></br>
                                <Grid  style={Bstyle} xs={5}>
                                    وثيقة أخرى ذات صلة
                                </Grid>

                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        fullwidth
                                        >
                                        رفع ملف
                                        <input
                                            name="relevantFile"
                                            type="file"
                                            hidden
                                            onChange={(event) => (setFieldValue('relevantFile', event.target.files[0]),setFilename1(event.target.files[0].name))}
                                            
                                        />
                                        </Button>
                                </Grid>
                                <Grid md={4} sm={12} style={Hstyle}>{filename1}</Grid>
                                <br></br>
{/************* 8.	Applicant declaration    **************/}
                                <Grid xs={12}><br></br><br></br><br></br>
                                    <FormTitle>8.	تصريح  مقدم الطلب </FormTitle>
                                </Grid>
                               <Grid  style={Hstyle} xs={12}>
                                    يعلن مقدم الطلب في سياق الدعوة الحالية لطلبات الهاكاثون ما يلي:
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                - استيفاء مقدم الطلب معايير الأهلية المحددة في القسم 6 من الإرشادات الخاصة بالمتقدمين .
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - مقدم الطلب متاح للمشاركة في ورشة عمل ملتقى خلق الأفكار عبر الإنترنت وبناء القدرات: "الفضاء المدني الرقمي والتقنيات الجديدة للديمقراطية" في 14-19 يونيو 2020 ؛
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - أن يكون لدى مقدم الطلب الكفاءات والمؤهلات المهنية لإدارة تطوير الأداة الرقميةITC tool المقترحة ;
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                - مقدم الطلب مسؤول بشكل مباشر عن إعداد وإدارة الأداة الرقميةITC tool المقترحة;
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - مقدم الطلب في وضع يسمح له بالبدء في تصميم وتطوير الأداة الرقميةITC tool المقترحة ضمن الإطار الزمني المحدد في القسم 7.1 من المبادئ التوجيهية لمقدمي الطلبات ؛
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - مقدم الطلب في وضع يسمح له بالبدء في تصميم وتطوير الأداة الرقميةITC tool المقترحة ضمن الإطار الزمني المحدد في القسم XX من المبادئ التوجيهية لمقدمي الطلبات ؛
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - في حالة التوصية بتلقي منحة للدعم المالي للتطوير الكامل للأداة الرقمية ، يقبل مقدم الطلب الشروط التعاقدية على النحو المنصوص عليه في الإرشادات الخاصة بمقدمي الطلبات
                                </Grid>
                                <Grid item xs={12}>
                                    <Agree
                                      name="checkMe"
                                      legend="بالضغط هنا ستوقع على تصريح تقديم الطلب"
                                      label="أنا موافق"
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                 <i>قبل النقر على زر "تقديم" ، يرجى التأكد من وضعك علامة على جميع المعلومات المقدمة وإرفاق جميع المستندات الداعمة اللازمة. قد يؤدي عدم تقديم المعلومات والوثائق المطلوبة خلال الموعد النهائي المحدد إلى رفض الطلب.
                                  <br></br>لن تتمكن من مراجعة نموذج الطلب بعد التقديم. إذا كنت واثقًا تمامًا من أن طلبك مكتمل وجاهز للتقديم ،انقر هنا زر "تقديم" أدناه.</i>
                                </Grid>
                                <Modal
                                        aria-labelledby="شكرا لك على تقديمك" className={classes.modal}
                                        open={open1}
                                        onClose={handleClose1}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                        timeout: 500,
                                        }}
                                    >
                                        <Fade in={open}>
                                        <div className={classes.paper}>
                                            <h2 id="transition-modal-title">شكرا لك على تقديمك {values.firstName} {values.lastName}</h2>
                                            </div>
                                        </Fade>
                                    </Modal>
                                    {(filled===true) ?
                                        (<Redirect to="/AR" />):""
                                    }
                                <Grid item xs={12}>
                                    <Buttonn >
                                        تقديم
                                    </Buttonn>
                                </Grid>
                            </Grid>
                        </Form>)}
                    </Formik>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default PersonalForm
