import React, {useRef,useEffect,useState} from 'react';
import {HeroContainer, HeroBg,Img,ImgBg,ImgWrap,Timer, TimerText,TimerWrapper1, TimerWrapper,StickyBarWrraper, StickyBar,StickyBarText, VideoBg, HeroContent, HeroH115, HeroP, HeroBtnWrapper, InfoRow, Column1, Column2 } from './HeroSectionElements'
import {NavBtnLink1,NavBtn} from '../Navbar/NavbarElemnts'
const HeroSection = () => {
const [hover, setHover] = useState(false)

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [timerDays1, setTimerDays1] = useState('00');
    const [timerHours1, setTimerHours1] = useState('00');
    const [timerMinutes1, setTimerMinutes1] = useState('00');
    const [timerSeconds1, setTimerSeconds1] = useState('00');
    let interval= useRef();

    const startTimer =() => {
        const countdownDate= new Date('April 22, 2021 22:00:00');
        const countdownDate1= new Date('May 16, 2021 22:00:00');

        interval=setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate- now;
            const days= Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours= Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000*  60));
            const seconds= Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                
            };
            
            const distance1 = countdownDate1- now;
            const days1= Math.floor(distance1 / (1000 * 60 * 60 * 24));
            const hours1= Math.floor((distance1 % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes1= Math.floor((distance1 % (1000 * 60 * 60)) / (1000*  60));
            const seconds1= Math.floor((distance1 % (1000 * 60)) / 1000);
            if (distance1 < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays1(days1);
                setTimerHours1(hours1);
                setTimerMinutes1(minutes1);
                setTimerSeconds1(seconds1);
            }
        }, 1000);
    };
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });
const onHover = () =>{
    setHover(!hover)
}

    return (
        <HeroContainer id="Hero">
            <HeroBg>
                <ImgBg src={require('../../images/heroback.png').default} alt={"Hackathon July Tunisia"}/>
            </HeroBg>
            <StickyBarWrraper>
            <StickyBar>
                <StickyBarText to='/'>EN</StickyBarText>
                <StickyBarText to='/FR'>FR</StickyBarText>
                <StickyBarText to='/AR'>عر</StickyBarText>
            </StickyBar>
            </StickyBarWrraper>
            <HeroContent>
                <InfoRow>
                    <Column1>
                    <HeroH115><b>#HackToAct</b> Hackathon</HeroH115>
                        <HeroP>
                        <b>“Hack to act” Hackathon will be held on 8 – 11 July 2021 in Tunis:</b> Med Dialogue Programme is providing a digital civic space for up to<b> 45 young activists, tech professionals and designers</b> from <b>Mashrek and Maghreb</b> countries, working as <b>“Hack Teams”,</b> to build relationships across <b>borders</b> and to design efficient <b>digital tools</b> aiming at developing sustainable <b>democracy</b>, promoting human <b>rights</b> and contributing to reforms and democratic changes in their home<b> countries</b>.
                        </HeroP>
                       <HeroP>
                       At the end of the Hackathon,<b> 3 “Hack teams”</b> will be selected and <b>granted</b> support of <b>up to 10.000 €</b>, to further develop, test and launch their digital tools.
                       </HeroP>
                        
                    </Column1>
                    
                    <Timer>
                       <TimerWrapper1>
                            <TimerText>Deadline For<br></br><small>Civil Society Activists</small></TimerText>
                        </TimerWrapper1> 
                        <TimerWrapper>
                            <TimerText>{timerDays}<br></br><small>Days</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerHours}<br></br><small>Hours</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerMinutes}<br></br><small>Minutes</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerSeconds}<br></br><small>Seconds</small></TimerText>
                       </TimerWrapper> 
                    </Timer>
                    <Timer>
                       <TimerWrapper1>
                            <TimerText>Deadline For<br></br><small>Tech Professionals & Designers</small></TimerText>
                        </TimerWrapper1> 
                        <TimerWrapper>
                            <TimerText>{timerDays1}<br></br><small>Days</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerHours1}<br></br><small>Hours</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerMinutes1}<br></br><small>Minutes</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerSeconds1}<br></br><small>Seconds</small></TimerText>
                       </TimerWrapper> 
                    </Timer>
                    
                </InfoRow>
                
            </HeroContent>
            <NavBtn>
                            <NavBtnLink1 to="apply"
                                smooth={true}
                                duration={1000}
                                spy={true}
                                exact='true'
                                offset={0}
                                activeClass="active">
                                    Apply Now!
                            </NavBtnLink1>
                    </NavBtn>
        </HeroContainer>
    )
}

export default HeroSection
