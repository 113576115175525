import React, { useState, useEffect }from 'react'
import Icon1 from '../../images/Powerful.svg'
import Icon2 from '../../images/undraw_Social_networking_re_i1ex.svg'
import Icon3 from '../../images/undraw_winners_ao2o(1).svg'
import{ServicesContainer,NavBtnLink1 ,NavBtnLink10 ,NavBtnLink2,ServicesWrapper2,ServicesWrapper1, ServicesH1, ServicesCardAR,ServicesCard1AR,ServicesCard2AR, ServicesWrapper, ServicesPAR,ServicesPfAR, ServicesIcon} from '../Services/ServiceElement'
import{NavBtn,NavBtnLink} from '../Topics/Topicele'
import { Grid } from '@material-ui/core';

var i = 0;
function change() {
  var doc = document.getElementById("ground");
  var color = ["#0075B4", "#4f72a5", "#9d6e97", "#EE6A88"];
  if (doc){
  doc.style.backgroundColor = color[i];
  i = (i + 1) % color.length;}
}
setInterval(change, 1000);


const Services = () => {
    
  
    return (
        <ServicesContainer id="services">
            <ServicesWrapper1>
                        <NavBtnLink2 id="ground" to="win"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            « Hack to act » جائزة الهاكاتون 
                    </NavBtnLink2>
                        <NavBtnLink10 to="/AR/FAQ">
                            الأسئلة‌ ‌الأكثر‌ ‌تداولًا‌ ‌
                    </NavBtnLink10>
            
                    <NavBtnLink1 to="timeline"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            الجدول الزمني
                    </NavBtnLink1>
                    
                    <NavBtnLink1 to="online"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            Ideathon
                    </NavBtnLink1>
            
                    <NavBtnLink1 to="apply"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            activeClass="active">
                            كيفية التسجيل
                    </NavBtnLink1>
            
                        <NavBtnLink1 to="topics"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            الحلول التكنولوجية المؤهلة
                    </NavBtnLink1>
            
                
                <NavBtnLink1 to="who"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            من يمكنه التسجيل؟
                    </NavBtnLink1>
                    
        </ServicesWrapper1>
        <ServicesWrapper>
                
                
                <ServicesCard2AR>
                    <ServicesH1>! احصل على الجائزة وابدأ المغامرة</ServicesH1><br></br>
                    <ServicesPAR> قم بالتصويت لزملائك المفضلين ! اعرض الحل الخاص بك وقم بإقناع مرشدينا وزملائك بالتصويت لك! اعمل على اختيار أداتك الرقمية من بين <b>أفضل 3 أدوات واحصل على دعم لتطوير فكرتك </b>  <ServicesPfAR> قيمته 10 آلاف يورو !</ServicesPfAR> </ServicesPAR>
                    <ServicesIcon src={Icon3}/>
                </ServicesCard2AR>
                <ServicesCard1AR>
                    <ServicesH1>! تعلم شيئًا جديدًا من زملائك </ServicesH1><br></br>
                    <ServicesPAR>
                    تبادل الأفكار والآراء<b> مع 45 شخصًا من 10 دول </b> وخلفيات مختلفة. اختر مرشدك الخاص وشكل فريق البرمجة الخاص بك !

                    </ServicesPAR>
                    <ServicesIcon src={Icon2}/>
                </ServicesCard1AR>
                <ServicesCardAR>
                    <ServicesH1>! ابن قدراتك</ServicesH1><br></br>
                    <ServicesPAR> احصل على تدريب وتوجيه من قبل مرشدينا فيما يتعلق بالتفكير التصميمي، الحقوق الرقمية، الديمقراطية وكذلك تطوير فكرة مشروعك !
                    </ServicesPAR>
                    <ServicesIcon src={Icon1}/>
                </ServicesCardAR>
            </ServicesWrapper>
            <ServicesWrapper2>
            <NavBtn>
                        <NavBtnLink to="/AR/Guidelines">
                                        اقرأ إرشادات التسجيل
                        </NavBtnLink>
            </NavBtn>
            </ServicesWrapper2>
        </ServicesContainer>
    )
}

export default Services
