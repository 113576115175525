import React, {useRef,useEffect,useState} from 'react';
import {HeroBg,TimerAR, TimerText,TimerWrapper1,ImgBg, TimerWrapper,StickyBarWrraper, StickyBar,StickyBarText,  HeroContent, HeroH1, HeroPAR,InfoRow, Column1} from '../HeroSection/HeroSectionElements'
import {HeroContainer} from './ArabicElements'
import {NavBtnLink1,NavBtn} from '../Navbar/NavbarElemnts'
const HeroSection = () => {

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [timerDays1, setTimerDays1] = useState('00');
    const [timerHours1, setTimerHours1] = useState('00');
    const [timerMinutes1, setTimerMinutes1] = useState('00');
    const [timerSeconds1, setTimerSeconds1] = useState('00');
    let interval= useRef();

    const startTimer =() => {
        const countdownDate= new Date('April 22, 2021 22:00:00');
        const countdownDate1= new Date('May 16, 2021 22:00:00');

        interval=setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate- now;
            const days= Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours= Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000*  60));
            const seconds= Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                
            };
            
            const distance1 = countdownDate1- now;
            const days1= Math.floor(distance1 / (1000 * 60 * 60 * 24));
            const hours1= Math.floor((distance1 % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes1= Math.floor((distance1 % (1000 * 60 * 60)) / (1000*  60));
            const seconds1= Math.floor((distance1 % (1000 * 60)) / 1000);
            if (distance1 < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays1(days1);
                setTimerHours1(hours1);
                setTimerMinutes1(minutes1);
                setTimerSeconds1(seconds1);
            }
        }, 1000);
    };
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });

    return (
        <HeroContainer id="Hero">
            <HeroBg>
                <ImgBg src={require('../../images/herobackfr1.png').default} alt={"هاكاثون البحر الأبيض المتوسط"}/>
            </HeroBg>
            <StickyBarWrraper>
            <StickyBar>
                <StickyBarText to='/'>EN</StickyBarText>
                <StickyBarText to='/FR'>FR</StickyBarText>
                <StickyBarText to='/AR'>عر</StickyBarText>
            </StickyBar>
            </StickyBarWrraper>
            <HeroContent>
                <InfoRow>
                    <Column1>
                    <HeroH1 style={{textAlign:"right"}}>#HackToAct هاكاثون</HeroH1>
                        <HeroPAR>
                        <b>سيعقد هاكاثون في الفترة ما بين 8 إلي 11 يوليو 2021 في
                        تونس:</b> يوفر برنامج حوار المتوسط مساحة مدنية رقمية لما يصل <b> إلى 45 من الناشطين الشباب،</b> 
                        <b> المبرمجين وأيضًا المصممين من دول المشرق والمغرب العربي</b> ، <b> للعمل كـ "فرق برمجة Hack
                        teams"</b> ، وبناء علاقات عبر <b>الحدود المحلية</b> وكذلك تصميم أدوات رقمية فعالة تهدف إلى تطوير
                        <b> ديمقراطية مستدامة </b>، تعزيز <b> حقوق الإنسان </b> والمساهمة في الإصلاحات والتغييرات الديمقراطية في
                        <b> بلدانهم الأم. </b>
                        </HeroPAR>
                       <HeroPAR>
                        في نهاية الهاكاثون، سيتم اختيار<b> 3 "فرق برمجة Hack teams"</b><b> ومنحها دعمًا يصل إلى 10.000
                        يورو</b> لمواصلة تطوير وإطلاق أدواتهم الرقمية.  
                       </HeroPAR>
                        
                    </Column1>
                    
                    <TimerAR>
                       <TimerWrapper1>
                            <TimerText>الموعد النهائي<br></br><small>نشطاء المجتمع المدني</small></TimerText>
                        </TimerWrapper1> 
                        <TimerWrapper>
                            <TimerText>{timerDays}<br></br><small>أيام</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerHours}<br></br><small>ساعات</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerMinutes}<br></br><small>دقيقة</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerSeconds}<br></br><small>ثواني</small></TimerText>
                       </TimerWrapper> 
                    </TimerAR>
                    <TimerAR>
                       <TimerWrapper1>
                            <TimerText>الموعد النهائي<br></br><small> المبرمجين والمصممين</small></TimerText>
                        </TimerWrapper1> 
                        <TimerWrapper>
                            <TimerText>{timerDays1}<br></br><small>أيام</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerHours1}<br></br><small>ساعات</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerMinutes1}<br></br><small>دقيقة</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerSeconds1}<br></br><small>ثواني</small></TimerText>
                       </TimerWrapper> 
                    </TimerAR>
                    
                </InfoRow>
            </HeroContent>
            <NavBtn>
                            <NavBtnLink1 to="apply"
                                smooth={true}
                                duration={1000}
                                spy={true}
                                exact='true'
                                offset={0}
                                activeClass="active">
                                     ! سجل الأن  
                            </NavBtnLink1>
                    </NavBtn>
        </HeroContainer>
    )
}

export default HeroSection
