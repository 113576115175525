import React from 'react'
import {Button} from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2,ServicesPfAR, Column3, TextWrapper, TopLine, Heading, SubtitleAR, BtnWrap, Img, ImgWrap } from '../InfoSection1/InfoElements'


const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, description2, buttonLabel,
     img, alt, primary, dark, dark2}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={"apply"}>
                <InfoWrapper>
                <Heading> كيفية التقدم بطلب؟</Heading>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine> {topLine}</TopLine>
                                <SubtitleAR>
                                    إذا كنت <b>ناشطًا في المجتمع المدني </b>
                                    ولديك فكرة عن <ServicesPfAR>أداة للحل الرقمي </ServicesPfAR>يرجى تقديم الطلب قبل 
                                    <b> 22 أبريل/ أفريل 2021 </b>عن طريق ملء هذا النموذج!
                                </SubtitleAR>
                                <BtnWrap>
                                     <Button to='/AR/FormCivic'
                                    >تقدم كناشط في المجتمع المدني </Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={require('../../images/teamwork.svg').default} alt={"فريق الهاكاثون"} />
                            </ImgWrap>
                        </Column2>
                        <Column3>
                            <TextWrapper>
                                <TopLine> {topLine}</TopLine>
                                <SubtitleAR darkText={darkText}>إذا كنت <b>مبرمج أو مصمم جرافيك، </b>يرجى التقديم قبل<b> 16 مايو/ ماي 2021 </b>عن طريق ملء هذا النموذج!</SubtitleAR>
                                <BtnWrap>
                                    <Button to='/AR/FormTech'
                                    >تقدم كمبرمج أو مصمم جرافيك</Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
                
            </InfoContainer>
           </>
    )
}

export default InfoSection
