import firebase from "firebase";

var firebaseConfig = {
    apiKey: "AIzaSyC82x9Zr3YgaP5vEtGsEhpZnSVkHFk5DNM",
    authDomain: "hackathon-e8f95.firebaseapp.com",
    projectId: "hackathon-e8f95",
    storageBucket: "hackathon-e8f95.appspot.com",
    messagingSenderId: "173286298283",
    appId: "1:173286298283:web:ffec2c2bda59d1d7cfddee",
    measurementId: "G-8B77B85RDS"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  export default firebase;