import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SettingsCellIcon from '@material-ui/icons/SettingsCell';
import WidgetsIcon from '@material-ui/icons/Widgets';
import WbIridescentIcon from '@material-ui/icons/WbIridescent';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import EditIcon from '@material-ui/icons/Edit';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { Headingg } from '../InfoSection2/InfoElements';
import VideoCallIcon from '@material-ui/icons/VideoCall';

const useStyles = makeStyles((theme) => ({
  
  paper: {
    padding: '6px 16px',
    width:'80%',
    background: "#bdbdbd",
    color:"#fff",
  },
  paper1: {
    width:'80%',
    padding: '6px 16px',
    background: "#7AAFC6",
    color:"#fff",
    
  },
  paper10: {
    width:'80%',
    padding: '6px 16px',
    background: "#7AAFC6",
    color:"#fff",
    
    '&:hover': {
        backgroundColor: "#fff",
        color:"#7AAFC6",
        transform: "scale3d(1.05, 1.05, 1.9)",
        
     },
  },
  paper2: {
    width:'450px',
    padding: '6px 16px',
    background: "#EE6A88",
    color:"#fff",
    marginLeft: '100px',
    '@media(max-Width: 780px)' : {
      marginLeft: '0',
      width:'100%',
    },
  },
  paper3: {
    width:'80%',
    padding: '6px 16px',
    background: "#F9564F",
    color:"#fff",
    '&:hover': {
        background: "#fff",
        color:"#F9564F"
     },
  },
  paper4: {
    width:'450px',
    padding: '6px 16px',
    background: "#3f51b5",
    color:"#fff",
    marginLeft: '100px',
    '@media(max-Width: 780px)' : {
      marginLeft: '0',
      width:'100%',
    },
  },
  timedotr: {
    background: "#EE6A88",
    color:"#fff",
  },
  timedotr1: {
    background: "#F9564F",
    color:"#fff",
  },
  timedotr2: {
    background: "#7AAFC6",
    color:"#fff",
  },
  textt:{
    padding: '8px 0px',
    color:"#7AAFC6",
    fontWeight: "bold",
  },
  texttt:{
    fontWeight: "bold",
  },
  
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  tet1:{
    padding: '8px 0px',
    color:"#EE6A88",
    fontWeight: "bold",
  },
  tet:{
    padding: '8px 0px',
    color:"#988B8E",
    fontWeight: "bold",
  },
  date:{
    padding: '8px 0px',
    color:"#3f51b5",
    fontWeight: "bold",
  },
  alert:{
    padding: '8px 0px',
    color:"#F9564F",
    fontWeight: "bold",
  },
  
}));

const Roadmap = () => {
const [hover, setHover] = useState(false)
const classes = useStyles();
const onHover = () =>{
    setHover(!hover)
}

    return (
      <>
        <Headingg>Indicative Timeline </Headingg>
        <Timeline  className={classes.MuiTimelineroot} id={'timeline'}>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.tet}>
                  1 April 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                <NotificationImportantIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography>Call for “Hack to act” opens</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem>
            <TimelineOppositeContent>
            <Typography className={classes.tet}>
                15 April 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot >
                < TimelapseIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography>Deadline for requesting clarification</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.alert}>
                22 April 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.timedotr1}>
                <HourglassEmptyIcon />
              </TimelineDot >
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper3}>
                <Typography><b>Deadline for CSOs applications with ideas of ITC tools</b></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem>
            <TimelineOppositeContent>
            <Typography className={classes.tet}>
              May 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot >
                <HowToRegIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography>Selection results announced: CSO participants</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.alert}>
              16 May 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.timedotr1}>
                <HourglassEmptyIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper3}>
                <Typography><b>Deadline for IT and designers applications</b></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          </Timeline>
          <TimelineItem>
            <TimelineOppositeContent>
            <Typography className={classes.tet}>
              19-20 May 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                <VoiceChatIcon/>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography>Webinar for shortlisted CSOs participants</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

<Timeline  className={classes.MuiTimelineroot} align="right">
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.date}>
                24-29 May 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary" >
                <VideoCallIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper4}>
                <Typography>Online Ideathon and capacity building workshop</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.date}>
              29 May 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <HowToRegIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper4}>
                <Typography>Selection of 15 ITC tools to participate in the Hackathon</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          </Timeline>
          <Timeline  className={classes.MuiTimelineroot} id={'timeline'}>
          <TimelineItem>
            <TimelineOppositeContent>
            <Typography className={classes.tet}>
              9 June 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot >
                <HowToRegIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography>Selection results announced: IT & Designer participants</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </Timeline>

        
          
          
        <Timeline  className={classes.MuiTimelineroot} align="right">
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.date}>
              28-29 June 
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                < VoiceChatIcon/>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper4}>
                <Typography>Online meetings: Constitution of “Hack teams”</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          </Timeline>
        <Timeline  className={classes.MuiTimelineroot} align="left">
          <TimelineItem >
            <TimelineOppositeContent>
              <Typography className={classes.textt}>
              8-11 July 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.timedotr2}>
                <WidgetsIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper1}>
                <Typography>“Hack to Act” Hackathon, Tunis, Tunisia</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.textt}>
              11 July 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.timedotr2}>
                <EmojiEventsIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper10}>
                <Typography className={classes.texttt}>Selection of 3 winning digital solutions/ ITC tools</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.textt}>
              July 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.timedotr2}>
                <EditIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper1} >
                <Typography>Signature of contracts for the implementation of winning ITC tools</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          </Timeline>
        <Timeline  className={classes.MuiTimelineroot} align="right">
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.tet1}>
              January 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.timedotr}>
                <SettingsCellIcon />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper2}>
                <Typography>3 digital solutions/ ITC tools produced </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </>
      );
}

export default Roadmap
