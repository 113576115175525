import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'
import {Link as LinkR} from 'react-router-dom'


export const Nav = styled.nav`
    background: #fff;
    hieght: 80px;
    padding-top: 12px;
    padding-bottom: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left:50px;
    font-size: 1rem;
    top:0;
    z-index: 10;
    border-bottom: 2px solid #0075B4;
    @media screen and (max-width: 960px) {
        transition : 0.8 all ease;
        padding-left:0;
        }
    @media screen and (max-width: 1000px){
            grid-template-columns: 1fr 1fr ;
            padding: 0 30px;
        }
        @media screen and (max-width: 768px){
            padding: 0 20px;
        }
        @media screen and (max-width: 768px){
            display: grid;
            grid-template-columns: 1fr;
            padding: 10px;
        }
    ` ;



export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between; 
    height: 80px;
    z-index: 1;
    width: 100%;
    position:relative;
    z-index:11;
    max-width: 1100px;
    
    `;

export const Logo=styled.div`
display: flex;
padding: 0 0px;

`;
export const NavLogo = styled(LinkS)`
    color:#393939;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 0;
    font-weight: bold;
    text-decoration: none;
    `;
export const NavLogo1 = styled.a`
    color:#393939;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 0;
    font-weight: bold;
    text-decoration: none;
    `;
export const MobileIcon = styled.div`
        display:none;

        @media screen and (max-width : 768px){
            align-items:center;
            display: block;
            position: absolute;
            margin-top:-15px;
            right:0;
            transform: translate(-100%,60%);
            font-size : 2rem;
            curser: pointer;
            color: #fff;
            background-color:#076D97;
            padding:5px;
            padding-bottom:0px;
        }
`;
export const MobileIcon2 = styled.div`
        display:none;
        @media screen and (max-width : 768px){
            align-items:center;
            display: block;
            position: relative;
            font-size : 2rem;
            curser: pointer;
            color: #fff;
            background-color:#076D97;
        }
`;
export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;

@media screen and (max-width : 768px){
    display: none;
}
`;

export const NavItem = styled.li`
height: 100%;
`;

export const NavLinks = styled(LinkS)`
    color:#393939;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    
&.active {
    border-bottom: 3px solid #01bf71;
}
`;
export const NavLinkch = styled(LinkS)`
    color:#393939;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    
&.active {
    border-bottom: 3px solid #01bf71;
}
`;
export const NavBtn= styled.nav`
    display: flex;
    align-items: center;
`

export const NavBtnLink = styled(LinkS)`
    font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
    background: #0075B4;
    align-items: center;
    white-space: nowrap;
    padding: 15px 22px;
    color: #fff;
    font-weight:500;
    height: 45px;
    font-size: 12px;
    margin: 15px 100px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    @media screen and (max-width: 786px){
        margin: 15px 20px;
        font-size: 12px;
    }
&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 13px 22px;
    border: 2px solid #0075B4;
    color: #0075B4;
}
`

export const ServicesIcon= styled.img`
    margin: 10px;
    @media screen and (max-width: 786px){
        margin:20px;
    }
`

export const Line= styled.div`
    border-left: 1px solid #393939;
    height: 82px;
    margin: 0 0px;
    @media screen and (max-width: 786px){
        margin: 5px 0px;
        height: 70px;
    }
`

export const Textt= styled.p`
    font-family: 'alwyn-new-rounded-web',sans-serif;
    padding-top: 25px;
    font-size: 11.3px;
    font-weight: 500;
`


export const NavBtnLink1 = styled(LinkS)`
    font-family: 'alwyn-new-rounded-web',sans-serif;
    Border-Radius: 50px;
    z-index:19;
    font-weight: bold;
    background: #0075B4;
    align-items: center;
    white-space: nowrap;
    padding: 15px 60px;
    margin: 50px 900px;
    color: #fff;
    height: 65px;
    font-size: 25px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    border: 2px solid #66D0BA;

&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 15px 60px;
    border: 2px solid #66D0BA;
    color: #0075B4;
}
@media screen and (max-width: 1000px){
    height: 55px;
    font-size: 18px;
    padding: 15px 22px;
    margin-top: 800px;
    margin-bottom: 0;
}
@media screen and (max-width: 786px){
    height: 55px;
    font-size: 18px;
    padding: 15px 22px;
    margin-top: 500px;
    margin-bottom: 0;
}
@media screen and (max-width: 786px){
    height: 45px;
    font-size: 16px;
    margin-top: 700px;
    margin-bottom: 0;
}
`


export const NavBtnLink1197 = styled(LinkS)`
    font-family: 'alwyn-new-rounded-web',sans-serif;
    Border-Radius: 50px;
    z-index:19;
    font-weight: bold;
    background: #0075B4;
    align-items: center;
    white-space: nowrap;
    padding: 15px 60px;
    margin: 50px 900px;
    color: #fff;
    height: 65px;
    font-size: 25px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    border: 2px solid #66D0BA;

&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 15px 60px;
    border: 2px solid #66D0BA;
    color: #0075B4;
}
@media screen and (max-width: 1000px){
    height: 55px;
    font-size: 18px;
    padding: 15px 22px;
    margin-top: 300px;
    margin-bottom: 0;
}
@media screen and (max-width: 786px){
    height: 55px;
    font-size: 18px;
    padding: 15px 22px;
    margin-top: 340px;
    margin-bottom: 0;
}
@media screen and (max-width: 480px){
    height: 45px;
    font-size: 16px;
    margin-top: 385px;
    margin-bottom: 0;
}
`
export const NavBtnLink12 = styled(LinkR)`
    font-family: 'alwyn-new-rounded-web',sans-serif;
    Border-Radius: 50px;
    z-index:19;
    font-weight: bold;
    background: #0075B4;
    align-items: center;
    white-space: nowrap;
    padding: 15px 60px;
    margin: 100px 750px;
    color: #fff;
    height: 65px;
    font-size: 25px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    border: 2px solid #66D0BA;

&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 15px 60px;
    border: 2px solid #66D0BA;
    color: #0075B4;
}
@media screen and (max-width: 1000px){
   
    margin: 250px 20px;
}
@media screen and (max-width: 786px){
    height: 55px;
    font-size: 18px;
    padding: 15px 22px;
    margin: 300px 20px;
}
@media screen and (max-width: 480px){
    height: 45px;
    font-size: 12px;
    margin: 300px 20px;
    margin-bottom:0px;
}
`
export const SocialIcon = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 150px;

`

export const SocialIconLink = styled.a`
 align-items: center;
 color: #393939;
 font-size: 25px;
 @media screen and (max-width: 786px){
    padding-left:25px;
    font-size: 20px;
}
`


export const Container = styled.div`
    position:relative;
    z-index:11;
    background:#fff;    
    width: ${({width}) => (width-15)}px;
    margin-top:75px;
    padding-left:15px;
    padding-bottom:15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
`;

export const Text= styled.div`
font-display: swap;
color:#0075B4;
font-size: 12.3px;
font-weight: 600;
padding-right: 15px;
padding-top: 12px;
outline: none;

margin-top:12px;
margin-left: 0px;
    text-decoration: none;
    &:hover{
        transition: all 0.8 ease-in-out;
        transition: color 0.4s linear;
        color: #06A5FA;
    }
`

export const Sty = styled.div`
font-family:"alwyn-new-rounded-web",sans-serif;
 display: flex;
 align-items: center;
 outline: none;

 background:#fff;
 color:#0076B5;
 font-size: 12.3px;
 font-weight: 600;
 &:hover{
    transition: all 0.8 ease-in-out;
    transition: color 0.4s linear;
    color: #06A5FA;
    outline: none;

}
`