import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SettingsCellIcon from '@material-ui/icons/SettingsCell';
import WidgetsIcon from '@material-ui/icons/Widgets';
import WbIridescentIcon from '@material-ui/icons/WbIridescent';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import EditIcon from '@material-ui/icons/Edit';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { Headingg } from '../InfoSection2/InfoElements';
import VideoCallIcon from '@material-ui/icons/VideoCall';

const useStyles = makeStyles((theme) => ({
  
  
  paper: {
    padding: '6px 16px',
    width:'80%',
    background: "#bdbdbd",
    color:"#fff",
    textAlign:"right",
  },
  paper1: {
    width:'80%',
    padding: '6px 16px',
    background: "#7AAFC6",
    color:"#fff",
    textAlign:"right",
    
  },
  paper10: {
    width:'80%',
    padding: '6px 16px',
    background: "#7AAFC6",
    color:"#fff",
    textAlign:"right",
    
    '&:hover': {
        backgroundColor: "#fff",
        color:"#7AAFC6",
        transform: "scale3d(1.05, 1.05, 1.9)",
        
     },
  },
  paper2: {
    width:'450px',
    padding: '6px 16px',
    background: "#EE6A88",
    textAlign:"right",
    color:"#fff",
    marginLeft: '100px',
    '@media(max-Width: 780px)' : {
      marginLeft: '0',
      width:'100%',
    },
  },
  paper3: {
    width:'80%',
    textAlign:"right",
    padding: '6px 16px',
    background: "#F9564F",
    color:"#fff",
    '&:hover': {
        background: "#fff",
        color:"#F9564F"
     },
  },
  paper4: {
    width:'450px',
    padding: '6px 16px',
    textAlign:"right",
    background: "#3f51b5",
    color:"#fff",
    marginLeft: '100px',
    '@media(max-Width: 780px)' : {
      marginLeft: '0',
      width:'100%',
    },
  },
  timedotr: {
    background: "#EE6A88",
    color:"#fff",
  },
  timedotr1: {
    background: "#F9564F",
    color:"#fff",
  },
  timedotr2: {
    background: "#7AAFC6",
    color:"#fff",
  },
  textt:{
    padding: '8px 0px',
    color:"#7AAFC6",
    fontWeight: "bold",
  },
  texttt:{
    fontWeight: "bold",
  },
  
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  tet1:{
    padding: '8px 0px',
    color:"#EE6A88",
    fontWeight: "bold",
  },
  tet:{
    padding: '8px 0px',
    color:"#988B8E",
    fontWeight: "bold",
  },
  date:{
    padding: '8px 0px',
    color:"#3f51b5",
    fontWeight: "bold",
  },
  alert:{
    padding: '8px 0px',
    color:"#F9564F",
    fontWeight: "bold",
  },
  
}));

const RoadmapFR = () => {
const [hover, setHover] = useState(false)
const classes = useStyles();
const onHover = () =>{
    setHover(!hover)
}

    return (
      <>
        <Headingg> الجدول الزمني التقريبي </Headingg>
        <Timeline  className={classes.MuiTimelineroot} id={'timeline'}>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.tet} style={{direction:"rtl"}}>
                  1 أبريل/ أفريل 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                <NotificationImportantIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography>إطلاق دعوة التقدم للترشيح</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem>
            <TimelineOppositeContent>
            <Typography className={classes.tet} style={{direction:"rtl"}}>
                15 أبريل/ أفريل 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot >
                < TimelapseIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography>الموعد النهائي لطلب الايضاحات من نشطاء المجتمع المدني</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.alert} style={{direction:"rtl"}}>
                 22 أبريل/ أفريل 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.timedotr1}>
                <HourglassEmptyIcon />
              </TimelineDot >
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper3}>
                <Typography><b>الموعد النهائي لدعوة نشطاء المجتمع المدني الذين لديهم أفكار لأدوات رقمية</b></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem>
            <TimelineOppositeContent>
            <Typography className={classes.tet} style={{direction:"rtl"}}>
              مايو/ ماي 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot >
                <HowToRegIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography>اعلان نتائج الاختيارات: المشاركين من المجتمع المدني</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.alert} style={{direction:"rtl"}}>
              16 مايو/ ماي 2021 
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.timedotr1}>
                <HourglassEmptyIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper3}>
                <Typography><b>الموعد النهائي لدعوة المبرمجين والمصممين</b></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
            <Typography className={classes.tet} style={{direction:"rtl"}}>
              20-19 مايو/ ماي 2021 
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                <VoiceChatIcon/>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography>ندوة عبر الإنترنت للنشطاء المُختارين</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          
        </Timeline>


<Timeline  className={classes.MuiTimelineroot} align="right">
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.date} style={{direction:"rtl"}}>
              24- 29 مايو/ ماي 2021 
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary" >
                <VideoCallIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper4} style={{direction:"rtl"}}>
                <Typography>ملتقي الأفكار وورشة بناء القدرات Ideathon على الانترنت</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.date} style={{direction:"rtl"}}>
              29 مايو/ ماي 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <HowToRegIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper4} style={{direction:"rtl"}}>
                <Typography>اختيار ما يصل إلى 15 أداة رقمية للمشاركة في الهاكاثون </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
          
          <TimelineItem>
            <TimelineOppositeContent>
            <Typography className={classes.tet} style={{direction:"rtl"}}>
              9 يونيو / جوان 2021  
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot >
                <HowToRegIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography>اغلان نتائج الاختيارات: المطورين والمصممين المشاركين </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          
        <Timeline  className={classes.MuiTimelineroot} align="right">
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.date} style={{direction:"rtl"}}>
              29-28 يونيو / جوان 2021 
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                < VoiceChatIcon/>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper4} style={{direction:"rtl"}}>
                <Typography>اجتماع أونلاين: التأسيس لفرق البرمجة </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          </Timeline>
        <Timeline  className={classes.MuiTimelineroot} align="left">
          <TimelineItem >
            <TimelineOppositeContent>
              <Typography className={classes.textt} style={{direction:"rtl"}}>
              11-8 يوليو /  جويلية 2021  
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.timedotr2}>
                <WidgetsIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper1} style={{direction:"rtl"}}>
                <Typography> هاكاثون Hack to Act،  في تونس العاصمة  </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.textt} style={{direction:"rtl"}}>
              11 يوليو / جويلية 2021  
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.timedotr2}>
                <EmojiEventsIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper10}>
                <Typography className={classes.texttt}>اختيار ثلاثة فائزين من الحلول الرقمية   </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.textt}>
               يوليو / جويلية 2021   
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.timedotr2}>
                <EditIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper1} >
                <Typography>توقيع العقود لتنفيذ الحلول الرقمية الفائزة </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          </Timeline>
        <Timeline  className={classes.MuiTimelineroot} align="right">
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className={classes.tet1}>
              يناير/ جانفي 2022 
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.timedotr}>
                <SettingsCellIcon />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper2}>
                <Typography>انتاج الثلاث أدوات رقمية </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </>
      );
}

export default RoadmapFR
