import React, {useRef,useEffect,useState} from 'react';
import {HeroContainer11, HeroBg,ImgBg,Img,ImgWrap,Timer, TimerText,TimerWrapper1, TimerWrapper,StickyBarWrraper, StickyBar,StickyBarText, VideoBg, HeroContent, HeroH11, HeroH24, HeroP, HeroBtnWrapper, InfoRow, Column112, Column2,HeroH22 } from '../HeroSection/HeroSectionElements'
import {NavBtnLink12,NavBtn} from '../Navbar/NavbarElemnts'
import {TopLine,FAQ,FAQ1,ServicesWrapper111,NavBtnLink11,NavBtnLink13} from '../Forms/Topicele'
const Header = () => {

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [timerDays1, setTimerDays1] = useState('00');
    const [timerHours1, setTimerHours1] = useState('00');
    const [timerMinutes1, setTimerMinutes1] = useState('00');
    const [timerSeconds1, setTimerSeconds1] = useState('00');
    let interval= useRef();

    const startTimer =() => {
        const countdownDate= new Date('May 16, 2021 00:00:00');

        interval=setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate- now;
            const days= Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours= Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000*  60));
            const seconds= Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                
            };
            
        }, 1000);
    };
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });

    return (
        <>
        <HeroContainer11 id="Hero">
            <HeroBg>
                <ImgBg src={require('../../images/herobackform1.png').default} alt={"Hackathon mediterranean"}/>
            </HeroBg>
            <StickyBarWrraper>
            <StickyBar>
                <StickyBarText to='/FAQ'>EN</StickyBarText>
                <StickyBarText to='/FR/FAQ'>FR</StickyBarText>
                <StickyBarText to='/AR/FAQ'>عر</StickyBarText>
            </StickyBar>
            </StickyBarWrraper>
            <HeroContent>
                <InfoRow>
                <br></br>
                    <br></br>
                    <Column112 style={{align:"right"}}>
                    
                    <HeroH11 to='/AR' ><b>#HackToAct</b> هاكاثون </HeroH11>
                    <br></br>
                        <HeroH24 >
                        <b>الأسئلة الأكثر تداولًا</b>
                        </HeroH24>
                        
                    </Column112>
                   
                    
                </InfoRow>
                
            </HeroContent>
            
            <NavBtn>
                            <NavBtnLink12 to="/AR">
                                    ! سجل الأن  
                            </NavBtnLink12>
                    </NavBtn>
        </HeroContainer11>
        <ServicesWrapper111>
                 <NavBtnLink13 style={{direction:'rtl'}} to="/AR">
                          8-11 يوليو/ جويلية 2021، #HackToAct
                </NavBtnLink13>
                <NavBtnLink11 to="/AR/Guidelines">
                        اقرأ إرشادات التسجيل
                </NavBtnLink11>
                <NavBtnLink11 to="/AR/FormCivic">
                         استمارة التقديم لنشطاء المجتمع المدني
                </NavBtnLink11>
                <NavBtnLink11 to="/AR/FormTech">
                         استمارة التقديم لمتخصصي تكنولوجيا المعلومات والمصممين 
                </NavBtnLink11>
        </ServicesWrapper111>
        </>
    )
}

export default Header
