import React from 'react'
import {Button} from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2,ServicesPf, ServicesPfAR, Column3, TextWrapper, TopLine, HeadingAR, SubtitleAR, BtnWrap, Img, ImgWrap } from '../Shortlist/ShortElements'
 
const Shortlist = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, description2, buttonLabel,
     img, alt, primary, dark, dark2}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={"online"}>
                <InfoWrapper>
                <HeadingAR> سيشارك المرشحون المختارون من منظمات المجتمع المدني<br></br> في ملتقى خلق الأفكار Ideathon قبل المشاركة في الهاكاثون Hackathon</HeadingAR>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                           <TextWrapper>
                                <TopLine> {topLine}</TopLine>
                                <SubtitleAR darkText={darkText}>
                                   خلال ورشة العمل هذه، سيتم تنمية مهارات المشاركين، تدريبهم وكذلك ارشادهم من أجل تطوير حلولهم الرقمية بشكل أكبر قبل طرحها. بعد جلسات العروض،
                                    <ServicesPf> سيتم اختيار ما يصل إلى 15 حلًا رقميًا </ServicesPf> 
                                    للمشاركة في هاكاثون"  Hack to Act"، في تونس العاصمة (تونس).</SubtitleAR>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={require('../../images/bulb.png').default} alt={"هاكاثون تونس"} />
                            </ImgWrap>
                        </Column2>
                        <Column3>
                            <TextWrapper>
                                <TopLine> {topLine}</TopLine>
                                <SubtitleAR>كخطوة أولى في مبادرة هاكاثون" Hack to Act"، سيشارك مرشحو منظمات المجتمع المدني المختارون والذين تقدموا بفكرة لحل رقمي في ورشة العمل عبر الانترنت الخاصة بملتقى خلق الأفكار وبناء القدرات  
                            Ideathon :
                            <ServicesPfAR> الفضاء المدني الرقمي والتقنيات الجديدة من أجل الديمقراطية"في نهاية شهر مايو 2021. </ServicesPfAR>
                            </SubtitleAR>
                                
                            </TextWrapper>
                            
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
                
            </InfoContainer>
           </>
    )
}

export default Shortlist
