import React from 'react'
import {Button} from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2,ServicesPf, Column3, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, Img, ImgWrap } from '../InfoSection1/InfoElements'


const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, description2, buttonLabel,
     img, alt, primary, dark, dark2}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={"apply"}>
                <InfoWrapper>
                <Heading> Comment postuler ?</Heading>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine> {topLine}</TopLine>
                                <Subtitle>Si vous êtes un <b>Activiste de la Société Civile,</b> porteur d’une idée de <ServicesPf> Solution Technologique,</ServicesPf> veuillez postuler avant le <b>22 avril 2021,</b> en remplissant le formulaire suivant !
                                </Subtitle>
                                <BtnWrap>
                                     <Button to='/FR/FormCivic'
                                    >Postuler en tant qu’activiste de la société civile</Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={require('../../images/teamwork.svg').default} alt={"Equipe pour le hackathon"} />
                            </ImgWrap>
                        </Column2>
                        <Column3>
                            <TextWrapper>
                                <TopLine> {topLine}</TopLine>
                                <Subtitle darkText={darkText}>Si vous êtes un <b>Développeur IT ou un Designer Graphique,</b> veuillez postuler avant le <b>16 Mai 2021</b> en remplissant le formulaire suivant !</Subtitle>
                                <BtnWrap>
                                    <Button to='/FR/FormTech'
                                    >Postuler en tant que professionnel de la technologie</Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
                
            </InfoContainer>
           </>
    )
}

export default InfoSection
