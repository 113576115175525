import React from 'react'
import {animateScroll as scroll} from 'react-scroll'
import { FaFacebook, FaInstagram, FaYoutube,FaTwitter } from 'react-icons/fa'
import {Logo, NavLogo,ServicesIcon,Text,Title,List,LiList,Inp, LihList,NavBtnLink1, Smalltitle,Smalltext, FooterContainer,Line, FooterWrap, SocialMedia, SocialMediaWrap,WebsiteRights,SocialIcon,SocialIconLink} from './FooterElement'



const Footer = () => {

    const toggleHome= () =>{
        scroll.scrollToTop();
    }
    const digStyle = {   
        textDecoration: 'None',
        }  
    return (
        <FooterContainer id="footer" >
            <FooterWrap>
                <SocialMedia>
                    <SocialMediaWrap>
                        <List>
                            <LihList >
                                <Logo>
                                    <NavLogo to='/' onClick={toggleHome}>
                                        <ServicesIcon src={require('../../images/Group1.png').default}  alt={"Med Dialogue for Rights and Equality"}
                                        ></ServicesIcon>
                                    </NavLogo>
                                        <Line></Line>
                                    <NavLogo to='/' onClick={toggleHome}>
                                        <ServicesIcon src={require('../../images/EuropeanUnion1.png').default} alt={"Union European"}
                                        ></ServicesIcon>
                                    </NavLogo>
                                    <Text>Programme funded by the<br></br>European Union</Text>
                                </Logo>
                            </LihList>
                            <LihList >
                                <Title>Think Mediterranean, Think the future!</Title>
                            </LihList>
                            <LihList >
                                <Smalltitle>Disclaimer</Smalltitle>
                            </LihList>
                            <LihList >
                                <Smalltext>This website was created and maintained with the financial support of <br></br>the European Union. Its contents are the sole responsibility of Particip GmbH<br></br> and do not necessarily reflect the views of the European Union.</Smalltext>
                            </LihList>
                        </List>
                        <WebsiteRights>Site Map
                            <List>
                                <br></br>
                                <a href="https://meddialogue.eu/about-us/" style={digStyle}><LiList >About</LiList></a>
                                <a href="https://meddialogue.eu/vision-med-2030/" style={digStyle}><LiList>Vision Med 2030</LiList></a>
                                <a href="https://meddialogue.eu/gallery/" style={digStyle}><LiList>Gallery</LiList></a>
                                <a href="https://meddialogue.eu/capacity-development-page/" style={digStyle}><LiList>Capacity Development</LiList></a>
                                <a href="https://meddialogue.eu/advocacy-campaigns/" style={digStyle}><LiList>Advocacy Campaigns</LiList></a>
                                <a href="https://meddialogue.eu/media/?wpv-category=publication#media-categories" style={digStyle}><LiList>Publications</LiList></a>
                                <a href="https://meddialogue.eu/media/" style={digStyle}><LiList>Media</LiList></a>
                            </List>
                        </WebsiteRights>
                        <List>
                            <LihList>
                                <Title>Subscribe to our newsletter</Title>
                            </LihList>
                            <LihList>
                                <Inp />
                                <NavBtnLink1>Send</NavBtnLink1>
                            </LihList>
                            <br></br><br></br>
                            <br></br><br></br>
                            <LihList>
                            <SocialIcon>
                                <SocialIconLink href="https://www.facebook.com/MedDialogue/" target="_blank" aria-label="Facebook">
                                    <FaFacebook/>
                                </SocialIconLink>
                            
                                <SocialIconLink href="https://www.instagram.com/med_dialogue/" target="_blank" aria-label="Instagram">
                                    <FaInstagram/>
                                </SocialIconLink>

                                <SocialIconLink href="https://twitter.com/Med_Dialogue" target="_blank" aria-label="Youtube">
                                    <FaTwitter/>
                                </SocialIconLink>

                                <SocialIconLink href="https://www.youtube.com/c/MedDialogue" target="_blank" aria-label="Youtube">
                                    <FaYoutube/>
                                </SocialIconLink>
                            </SocialIcon>
                            </LihList>
                        </List>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap><br/>
        </FooterContainer>
    )
}

export default Footer
