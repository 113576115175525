import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {HeroH112} from '../HeroSection/HeroSectionElements';
import Header from './headerAR';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '30px 0 30px 0',
    
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    textAlign:'center',
    alignContent:'center',
    display:'block',
    alignItems:'center'
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    textAlign:'center',
    padding:'5px'
  },
  paper:{
    backgroundColor:'#EE6A88',
    color:'#fff',
    textAlign:'center',
    alignContent:'center',
    alignItems:'center',
    "& .MuiAccordionSummary-content": { display: "block" }
  }
}));

const Faq = () => {
  const classes = useStyles();

  return (
    <>
        <Header/>
    <div className={classes.root}>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.paper}
        >
          <Typography className={classes.heading} style={{direction:"rtl"}}>ما هو ملتقى الأفكار Ideathon المعنون "الفضاء المدني الرقمي والتقنيات الجديدة من أجل الديمقراطية"؟</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify",direction:"rtl"}}>
            كخطوة أولى في مبادرة هاكاثون "الملتقى البرمجي للعمل "Hack to act ، سيشارك مرشحو منظمات المجتمع المدني المختارون الذين تقدموا بالفعل بأفكار لحلول رقمية في ورشة عمل ملتقى الأفكار وبناء القدراتIdeathon  المقامة على الانترنت تحت عنوان: <b>"الفضاء المدني الرقمي والتقنيات الجديدة من أجل الديمقراطية"</b> في نهاية شهر مايو/ ماي 2021.
          <br></br>
          خلال ورشة العمل هذه، سيتم تدريب المشاركين وتوجيههم نحو تطوير أكبر لأفكارهم قبل عرضها في جلسة مخصصة. وبعد انعقاد جلسة العروض،<b> سيتم اختيار ما يصل إلى 15 حلول رقمية  </b>للمشاركة في هاكاثون "الملتقى البرمجي للعمل "Hack to act ، والذي سينعقد في تونس العاصمة (تونس).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>ما هي جلسة العرض الختامية للهاكاثون ؟</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify",direction:"rtl"}}>
خلال هاكاثون "الملتقى البرمجي للعمل "Hack to act،  سيعمل ويتنافس ما يصل إلي 15 "فرق برمجة "Hack teams لعرض مشاريعهم على زملائهم والموجهين أيضًا. ولبلوغ هذه الغاية، سيتعين على كل "فريق برمجة "Hack team إعداد نموذج أولي لحل رقمي جاهز للعمل، ومن ثم عرض خدماته ووظيفته الرئيسية للجمهور ومنحهم رؤية واضحة حول كيفية عمله. بعد جلسة العروض، سيتم اختيار 3 حلول رقمية ومنحها دعمًا تقنيًا وماليًا يصل إلى 10 آلاف يورو.          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>هل أحتاج أن أكون عضوًا في منظمة مجتمع مدني للتقدم إلى الهاكاثون بفكرة حل رقمي؟</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify",direction:"rtl"}}>
الإجابة لا، لست بحاجة إلى ذلك. لكن من الضروري وصف خلفيتك وخبراتك كمواطن نشط مشتبك بموضوع بالديمقراطية. يمكن أن يشمل ذلك الخبرات التطوعية، المشاركة المجتمعية، الانخراط في الأنشطة والاتحادات الطلابية، الحركات المدنية، المبادرات الإعلامية، المجموعات السياسية، وما إلى ذلك.          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading} style={{direction:"rtl"}}>أنا ناشط في المجتمع المدني، هل أحتاج إلى فريق برمجة كامل full Hack team بما في ذلك مصمم ومطور عند التقدم بالحل الرقمي الخاص بي؟ إذا كانت الإجابة لا، ماذا عن تكوين فرق البرمجة Hack teams؟</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{textAlign:"justify",direction:"rtl"}}>
            على كل ناشط في المجتمع المدني التقدم بأداة لحل رقمي  ITC tool، في مرحلة التقدم لن تكون هناك  حاجة إلى تقديم تطبيقات "جاهزة للاستخدام النهائي" من قبل فريق البرمجة  Hack team.حيث سيتم تخصيص اجتماعين عبر الإنترنت فيما بعد لتكوين فرق البرمجة Hack teams، وذلك بعد الانتهاء من نشاطات ملتقى الأفكار .Ideathon 
            <br></br>
            وبهدف تكوين فرق برمجة Hack teams بطريقة تشاركية، يجب على كل ناشط في منظمات المجتمع المدني إقناع المطورين والمصممين الذين تقدموا بالفعل بشكل مستقل بالانضمام إلى فريقه (مطور واحد + مصمم واحد (. ومن أجل هذا، ستكون الجلسات المنعقدة عبر الإنترنت مصممة لتقديم نشطاء منظمات المجتمع المدني لحلولهم بشكل فردي، بالإضافة إلي المطورين والمصممين، وذلك بهدف اختيار الحل الرقمي بالإضافة إلي الفريق الذي يريدون الانتماء إليه (من خلال عملية تصويت سريعة عبر الإنترنت).
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br></br>
<Typography className={classes.heading2} style={{direction:"rtl"}} > إذا كانت لديك أسئلة، فلا تتردد في مراسلتنا على <b> hela.bensalah@particip.com </b> سيتم نشر سؤالك في هذا القسم.</Typography>
      
    </div>
    </>
  );
}

export default Faq
