import React, { useState} from 'react'
import {Button} from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow,Img1, Column1,List,LiList, Column2,ServicesPf, Column3, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, Img, ImgWrap } from './InfoElements'
 
const InfoSection2 = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, description2, buttonLabel,
     img, alt, primary, dark, dark2}) => {


    return (
        <>
            <InfoContainer lightBg={lightBg} id={"who"}>
                <InfoWrapper>
                <Heading> Who Can Apply ? </Heading>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <List>
                                <LiList><Img1 src={require('../../images/Frame7.png').default}/>Be nationals of and/or residents in one of the following countries: Algeria, Egypt, Jordan, Lebanon, Libya, Mauritania, Morocco, Palestine, Syria, Tunisia. </LiList>
                                <LiList><Img1 src={require('../../images/Frame8.png').default}/>Be civil society actors, IT (tech professionals) or designers.</LiList>
                                <LiList><Img1 src={require('../../images/Group-30.png').default}/>Be actively engaged in their communities and having demonstrated to have an impact on the ground.</LiList>
                            </List>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={require('../../images/develop.png').default} alt={"Hackathon Opportunity"} />
                            </ImgWrap>
                        </Column2>
                        <Column3>
                            <List>
                                <LiList><Img1 src={require('../../images/Frame7.png').default}/>Have an expertise and experience in new technologies tools and: Public transparency and accountability, or Community-building, or Active citizenship.</LiList>
                                <LiList><Img1 src={require('../../images/Frame7.png').default}/>Be part of an organisation that can manage, at least, small-scale operations (not mandatory for IT and designers applicants).</LiList>
                                <LiList><Img1 src={require('../../images/Group-30.png').default}/>Have a good command of English and/or Arabic and/or French.</LiList>
                            </List>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
                
            </InfoContainer>
           </>
    )
}

export default InfoSection2
