import React, {useState} from 'react'
import { Document, Page,ReactPDF } from 'react-pdf';
import file from './GuidelinesAR.pdf'
const GuidelineAR = () =>  {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

    return (
    <object data={file} type="application/pdf" width="100%" height="600vh">
          <p style={{direction:"rtl"}}>لا يحتوي متصفح الويب الخاص بك على مكون إضافي بتنسيق PDF.
             بدلا من ذلك يمكنك ذلك <a href={file} download> تنزيل إرشادات التسجيل</a></p>
    </object>
    )
}

export default GuidelineAR
