import styled from 'styled-components'

export const HeroContainer = styled.div`
       background: #c0c0c0c;
       padding-top:25px;
       display: block;
       justify-content: center;
       height: 681px;
       position: relative;
       z-index: 1; 

       @media screen and (max-width : 1000px){
        height: 1000px;
    }
       
        @media screen and (max-width : 768px){
            height: 1100px;
            margin-bottom:100px;
        }
        @media screen and (max-width : 480px){
            height: 800px;
            margin-bottom:300px;
        }
`
