import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    // component default is "inline-flex", using "flex" makes the
    // label + control group use the entire width of the parent element
    display: "flex",
    // component default is "flex-start", using "space-between" pushes
    // both flexed content to the right and left edges of the flexbox
    // Note: the content is aligned to the right by default because
    // the 'labelPlacement="start"' component prop changes the flexbox
    direction: "rtl",
    justifyContent: "space-between",
  },
});
const SelectWrapper = ({
  name,
  options,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const classes = useStyles();
  const handleChange = evt => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    variant: 'outlined',
    fullWidth: true,
    onChange: handleChange
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <TextField classes={classes.root} {...configSelect} style={{minWidth:"230px"}}>
      {Object.keys(options).map((item, pos) => {
        return (
          <MenuItem key={pos} value={item}>
            {options[item]}
          </MenuItem>
        )
      })}
    </TextField>
  );
};

export default SelectWrapper;