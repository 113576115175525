import React, {useState} from 'react'
import Navbar from '../../components/Navbar';
import Navbar2 from '../../components/Navbar2';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Forms from '../../components/Forms/TechLandingAR';
// import TechForm from '../../components/TechForm/TechFormAR';

const FormTechAR = () => {
    const [isOpen, setisOpen] = useState(false)

    const toggle = () =>{
        setisOpen(!isOpen)
    }
    return (
        <>
        <Navbar toggle={toggle}/>
        <Navbar2/>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Forms/>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Footer/>
        </>
    )
}

export default FormTechAR
