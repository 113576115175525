import React, {useState} from 'react'
import { Document, Page,ReactPDF } from 'react-pdf';
import file from './GuidelinesFR.pdf'
const GuidelineFR = () =>  {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

    return (
    <object data={file} type="application/pdf" width="100%" height="600vh">
       <p>Votre navigateur Web n'a pas de plugin PDF.
           Au lieu de cela, vous pouvez <a href={file}>Téléchargez les lignes directrices.</a></p>
    </object>
    )
}

export default GuidelineFR
