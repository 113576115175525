
import React, {useState} from 'react'
import Navbar from '../components/Navbar';
import Navbar2 from '../components/Navbar2';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import TechLanding from '../components/Forms/TechLanding';
// import TechForm from '../components/TechForm/Index';

const FormTech = () => {
    const [isOpen, setisOpen] = useState(false)

    const toggle = () =>{
        setisOpen(!isOpen)
    }
    return (
        <>
        <Navbar toggle={toggle}/>
        <Navbar2/>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <TechLanding/>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Footer/>
        </>
    )
}

export default FormTech
