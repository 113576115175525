import React, {useRef,useEffect,useState} from 'react';
import {HeroContainer1, HeroBg,Img,ImgBg,ImgWrap,Timer, TimerText,TimerWrapper1, TimerWrapper,StickyBarWrraper, StickyBar,StickyBarText, VideoBg, HeroContent, HeroH11, HeroH2, HeroP, HeroBtnWrapper, InfoRow, Column11, Column2,HeroH22 } from '../HeroSection/HeroSectionElements'
import {NavBtnLink1,NavBtnLink1197,NavBtn} from '../Navbar/NavbarElemnts'
import {TopLine,TextwMatfokch,TextwMatfokch1,ServicesWrapper1,NavBtnLink11} from './Topicele'
const FormTech = () => {
const [hover, setHover] = useState(false)

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [timerDays1, setTimerDays1] = useState('00');
    const [timerHours1, setTimerHours1] = useState('00');
    const [timerMinutes1, setTimerMinutes1] = useState('00');
    const [timerSeconds1, setTimerSeconds1] = useState('00');
    let interval= useRef();

    const startTimer =() => {
        const countdownDate= new Date('May 16, 2021 22:00:00');

        interval=setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate- now;
            const days= Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours= Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000*  60));
            const seconds= Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                
            };
            
        }, 1000);
    };
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });
const onHover = () =>{
    setHover(!hover)
}

    return (
        <>
        <HeroContainer1 id="Hero">
            <HeroBg>
                <ImgBg src={require('../../images/herobackform1.png').default} alt={"IT professionals Hackathon Application"} />
            </HeroBg>
            <StickyBarWrraper>
            <StickyBar>
                <StickyBarText to='/FormTech'>EN</StickyBarText>
                <StickyBarText to='/FR/FormTech'>FR</StickyBarText>
                <StickyBarText to='/AR/FormTech'>عر</StickyBarText>
            </StickyBar>
            </StickyBarWrraper>
            <HeroContent>
                <InfoRow>
                    <Column11>
                    <HeroH11 to='/'><b>#HackToAct</b> Hackathon</HeroH11>
                        <HeroH2>
                        <HeroH22>Application Form for </HeroH22><br></br><b>IT Professionals & Designers is Closed</b>
                        </HeroH2>
                        
                    </Column11>
                    
                    <Timer>
                       <TimerWrapper1>
                            <TimerText>Deadline For<br></br><small>IT Professionals & Designers</small></TimerText>
                        </TimerWrapper1> 
                        <TimerWrapper>
                            <TimerText>{timerDays}<br></br><small>Days</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerHours}<br></br><small>Hours</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerMinutes}<br></br><small>Minutes</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerSeconds}<br></br><small>Seconds</small></TimerText>
                       </TimerWrapper> 
                    </Timer>
                    
                    
                </InfoRow>
                
            </HeroContent>
            
            {/* <NavBtn>
                            <NavBtnLink1197 to="apply"
                                smooth={true}
                                duration={1000}
                                spy={true}
                                exact='true'
                                offset={0}
                                activeClass="active">
                                    Apply Now!
                            </NavBtnLink1197>
                    </NavBtn> */}
        </HeroContainer1>
        <ServicesWrapper1>
                <NavBtnLink11 to="/">
                        #HackToAct Hackathon 8- 11 July 2021
                </NavBtnLink11>
                <NavBtnLink11 to="/Guidelines">
                        Read the Guidelines for applicants
                </NavBtnLink11>
                <NavBtnLink11 to="/FormCivic">
                        Civil Society Activists Application Form
                </NavBtnLink11>
        </ServicesWrapper1>
        {/* <TopLine>Are you an <TextwMatfokch1> IT Professional / Designer</TextwMatfokch1> looking for new <b>Challenges?</b><br></br>
                If so, this is the right place to be !<br></br>
                Before applying, please carefully read <b><TextwMatfokch to="/Guidelines">The Guidelines for Applicants,</TextwMatfokch></b><br></br>
                then submit your candidature <b>before 16 May!</b><br></br>
        </TopLine> */}
        </>
    )
}

export default FormTech
