import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography
} from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  multilineColor: {
    color:'#000',
    alignItems: 'left',
    justifyContent: 'center',
    textAlign:'left',
    padding:'14px',
    fontFamily:"Segoe UI",
    fontWeight:'600',
    paddingLeft:"3px",
    fontSize:"0.95rem",
    lineHeight:"1.5"
  },
}));

const CheckboxWrapper = ({
  name,
  label,
  legend,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const classes = useStyles();

  const handleChange = evt => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
  };

  const configCheckbox = {
    ...field,
    onChange: handleChange
  };

  const configFormControl = {};
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    configFormControl.helperText = meta.error;

  }

  return (
    <FormControl {...configFormControl}>
      <FormLabel component="legend" className= {classes.multilineColor}>{legend}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox {...configCheckbox} />}
          label={label}
        />
      </FormGroup>
      <Typography style={{color:"red"}}>{configFormControl.helperText}</Typography>

    </FormControl>
  );
};

export default CheckboxWrapper;
