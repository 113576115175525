import React, {useRef,useEffect,useState} from 'react';
import {HeroContainer1, HeroBg,ImgBg,Img,ImgWrap,Timer, TimerText,TimerWrapper1, TimerWrapper,StickyBarWrraper, StickyBar,StickyBarText, VideoBg, HeroContent, HeroH11, HeroH2, HeroP, HeroBtnWrapper, InfoRow, Column11, Column2,HeroH22 } from '../HeroSection/HeroSectionElements'
import {NavBtnLink1,NavBtnLink1197,NavBtn} from '../Navbar/NavbarElemnts'
import {TopLineAR,TextwMatfokch,TextwMatfokch1,ServicesWrapper1,NavBtnLink11} from './Topicele'
const FormCivicAR= () => {
const [hover, setHover] = useState(false)

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [timerDays1, setTimerDays1] = useState('00');
    const [timerHours1, setTimerHours1] = useState('00');
    const [timerMinutes1, setTimerMinutes1] = useState('00');
    const [timerSeconds1, setTimerSeconds1] = useState('00');
    let interval= useRef();

    const startTimer =() => {
        const countdownDate= new Date('May 06, 2021 12:00:00');

        interval=setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate- now;
            const days= Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours= Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000*  60));
            const seconds= Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                
            };
            
        }, 1000);
    };
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });
const onHover = () =>{
    setHover(!hover)
}

    return (
        <>
        <HeroContainer1 id="Hero">
            <HeroBg>
                <ImgBg src={require('../../images/herobackform1.png').default} alt={"استمارة التقديم هاكاثون"}/>
            </HeroBg>
            <StickyBarWrraper>
            <StickyBar>
                <StickyBarText to='/FormCivic'>EN</StickyBarText>
                <StickyBarText to='/FR/FormCivic'>FR</StickyBarText>
                <StickyBarText to='/AR/FormCivic'>عر</StickyBarText>
            </StickyBar>
            </StickyBarWrraper>
            <HeroContent>
                <InfoRow>
                    <Column11 style={{direction:"rtl", textAlign:"right"}}>
                    <HeroH11 to='/AR'><b>#HackToAct</b> هاكاثون </HeroH11>
                        <HeroH2 style={{direction:"rtl", textAlign:"right"}}>
                        {/* <b> استمارة التقديم لنشطاء المجتمع المدني ! </b> */}
                        <b> لقد انتهت الأجال المخصصة للتسجيل<br></br> بالنسبة لنشطاء المجتمع المدني ! </b>
                        </HeroH2>
                        
                    </Column11>
                    
                    <Timer>
                       <TimerWrapper1>
                            <TimerText>الموعد النهائي<br></br><small>  لنشطاء المجتمع المدني </small></TimerText>
                        </TimerWrapper1> 
                        <TimerWrapper>
                            <TimerText>{timerDays}<br></br><small>أيام</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerHours}<br></br><small>ساعات</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerMinutes}<br></br><small>دقيقة</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerSeconds}<br></br><small>ثواني</small></TimerText>
                       </TimerWrapper> 
                    </Timer>
                    
                    
                </InfoRow>
                
            </HeroContent>
        </HeroContainer1>
        <ServicesWrapper1>
                <NavBtnLink11 style={{direction:'rtl'}} to="/AR">
                          11-8    يوليو/ جويلية 2021، #HackToAct
                </NavBtnLink11>
                <NavBtnLink11 to="/AR/Guidelines">
                        اقرأ إرشادات التسجيل
                </NavBtnLink11>
                <NavBtnLink11 to="/AR/FormTech">
                         التسجيل للمبرمجين ومطوري الجرافيك
                </NavBtnLink11>
        </ServicesWrapper1>
            <br></br>
            <br></br>
            <br></br>
        
        </>
    )
}

export default FormCivicAR
