import styled from 'styled-components'

export const InfoContainer = styled.div`
        color:#000;       
       @media screen and (max-width: 768px) {
           padding: 5px;
       }
       @media screen and (max-width: 480px) {
              padding: 5px;
          }
       `

export const InfoWrapper = styled.div`
       display: grid;
       z-index: 10;
       height: auto;
       width: 100%;
       max-wdith: 1100px;
       margin-right: auto;
       align-items:center;
       margin-left: auto;
       padding: 50px 80px;
       justify-content: center;
       @media screen and (max-width: 768px) {
              
              padding: 0 30px;
       }
`

export const TopLine = styled.p`
       color: #01bf71;
       font-size: 16px;
       line-height: 1.3;
       font-weight: 700;
       letter-spacing: 1.4px;
       text-transform: uppercase;
       margin-bottom: 16px;
       text-align:center;
`
export const Heading = styled.h1`
       color:#393939;
       font-size: 32px;
       line-height: 1.1;
       font-weight: bold;
       text-align:center;
       margin-bottom: 16px;

       @media screen and (max-width: 480px){
           font-size: 24px;
       }
`
export const FormTitle = styled.p`
       color: #7AAFC6;
       font-size: 24px;
       font-weight: 500;
       margin-bottom: 16px;
`