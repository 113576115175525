import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md'
import {Link as LinkR} from 'react-router-dom'

export const HeroContainer = styled.div`
       background: #c0c0c0c;
       display: block;
       justify-content: center;
       height: 648px;
       padding-top:25px;
       position: relative;
       z-index: 1; 

       @media screen and (max-width : 1000px){
        height: 700px;
    }
       
        @media screen and (max-width : 768px){
            height: 700px;
            margin-bottom:100px;
        }
        @media screen and (max-width : 480px){
            height: 700px;
            margin-bottom:300px;
        }
`
export const HeroContainer1 = styled.div`
       background: #c0c0c0c;
       display: block;
       justify-content: center;
       height: 340px;
       padding-top:25px;
       position: relative;
       z-index: 1; 

       @media screen and (max-width : 1000px){
        height: 390px;
    }
       
        @media screen and (max-width : 768px){
            height: 360px;
            margin-bottom:100px;
        }
        @media screen and (max-width : 480px){
            height: 380px;
        }
`

export const HeroContainer11 = styled.div`
       background: #c0c0c0c;
       display: block;
       justify-content: center;
       height: 340px;
       padding-top:25px;
       position: relative;
       z-index: 1; 

       @media screen and (max-width : 1000px){
        height: 400px;
    }
       
        @media screen and (max-width : 768px){
            height: 400px;
            margin-bottom:100px;
        }
        @media screen and (max-width : 480px){
            height: 380px;
        }
`
export  const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right:0;
    bottom:0;
    left:0;
    width:100%;
    height:100%;
    overflow: hidden;
    background-repeat: repeat-y;
`
export  const ImgBg = styled.img`
@media screen and (min-width : 1920px){
            width:100%;
        }

`
export const VideoBg = styled.img`
        width: 100%;
        height:100%;
        -o-object-fit: cover;
        object-fit: cover;
        background: #232a34;        
`

export const HeroContent =styled.div`
    z-index : 6;
    max-width: 1200px;
    position: absolute;
    display: block;
    align-items: center;
    @media screen and (max-width : 480px){
        align-items: normal;
        
    }
`

export const HeroH1 = styled.h1`
    color: #fff;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size:48px;
    font-weight:bold;
    border-raduis:50px;
    text-align: left;

    @media screen and (max-width : 768px){
        font-size: 40px;
    }

    @media screen and (max-width : 480px){
        font-size: 32px;
    }
    @media screen and (max-width : 439px){
        font-size: 24px;
    }
`
export const HeroH112 = styled.h1`
    margin-top:30px;
    color: #EE6A88;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size:48px;
    font-weight:bold;
    border-raduis:50px;
    text-align: center;
    &:hover{
        color: rgb(101, 168, 198);
    }
    @media screen and (max-width : 768px){
        font-size: 40px;
    }

    @media screen and (max-width : 480px){
        font-size: 32px;
    }
    @media screen and (max-width : 439px){
        font-size: 24px;
    }
`
export const HeroH11 = styled(LinkR)`
    text-decoration:none;
    color: #fff;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size:48px;
    font-weight:600;
    border-raduis:50px;
    text-align: left;

    @media screen and (max-width : 768px){
        font-size: 40px;
    }

    @media screen and (max-width : 480px){
        font-size: 32px;
    }
    @media screen and (max-width : 439px){
        font-size: 24px;
    }
`

export const HeroH115 = styled.h1`
    color: #fff;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size:48px;
    font-weight:600;
    border-raduis:50px;
    text-align: left;

    @media screen and (max-width : 768px){
        font-size: 40px;
    }

    @media screen and (max-width : 480px){
        font-size: 32px;
    }
    @media screen and (max-width : 439px){
        font-size: 24px;
    }
`

export const HeroH2 = styled.h2`
    color: #1b3259;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size:32px;
    font-weight:bold;
    border-raduis:50px;
    text-align: left;

    @media screen and (max-width : 768px){
        font-size: 40px;
    }

    @media screen and (max-width : 480px){
        font-size: 32px;
    }
    @media screen and (max-width : 439px){
        font-size: 24px;
    }
`
export const HeroH22 = styled.b`
    color: #fff;
    
`

export const HeroH24 = styled.h2`
    color: #1b3259;
    text-align:right;
    direction:rtl;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size:32px;
    font-weight:bold;
    border-raduis:50px;

    @media screen and (max-width : 768px){
        font-size: 40px;
    }

    @media screen and (max-width : 480px){
        font-size: 32px;
    }
    @media screen and (max-width : 439px){
        font-size: 24px;
    }
`

export const HeroP = styled.p`
    margin-top: 12px;
    color: #fff;
    font-size: 18px;
    text-align: justify;
    max-width: 1000px;

    @media screen and (max-width : 768px){
        font-size: 16px;
    }

    @media screen and (max-width : 480px){
        font-size: 14px;
    }
    @media screen and (max-width : 439px){
        font-size: 12px;
    }

`

export const HeroBtnWrapper =styled.div`
    margin-top: 32px;
    display: flex;
    align-items: center;
`




export const InfoRow= styled.div`
       display: inline;
       padding: 20px 0px;
       padding-right:10px;
`

export const Column1 = styled.div`
       margin-bottom: 15px;
       width:70%;
       border-radius: 0 80px 80px 0;
       background-color:rgb(101, 168, 198,0.95);
       padding: 15px 50px 30px 30px;
       @media screen and (max-width: 768px) {
        width:90%;
    }
    @media screen and (max-width: 480px) {
        width:100%;
    }
`

export const Column11 = styled.div`
       margin-bottom: 15px;
       width:100%;
       min-width:450px;
       border-radius: 0 80px 80px 0;
       background-color:rgb(101, 168, 198,0.95);
       padding: 15px 50px 30px 30px;
       @media screen and (max-width: 768px) {
        width:90%;
        min-width:0;
    }
    @media screen and (max-width: 480px) {
        width:100%;
    }
`

export const Column112 = styled.div`
       margin-bottom: 15px;
       text-align: right;
       
       width:100%;
       min-width:450px;
       border-radius: 0 80px 80px 0;
       background-color:rgb(101, 168, 198,0.95);
       padding: 15px 50px 30px 30px;
       @media screen and (max-width: 768px) {
        width:90%;
        min-width:0;

    }
    @media screen and (max-width: 480px) {
        width:100%;
        min-width:0;
    }
`
export const Column2 = styled.div`
       width:70%;
       z-index:-1;
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
    @media screen and (max-width: 768px) {
        display:none;
    }
`

export const Img = styled.img`
       width: 100%;
       margin-left: 75px;
       padding-right: 0;
       @media screen and (max-width : 1000px){
        margin-left: 20px;
        margin-top: 110px;
    }
       @media screen and (max-width: 480px) {
        margin: 0;
        }
`

export const Timer = styled.div`
    color:#fff;
    background-color: rgb(249,86,79,0.95);
    align-items: center;
    font-weight:bold; 
    vertical-align: middle;
    display:flex;
    margin-top:20px;
    flex-direction:row;
    border-radius: 0 25px 25px 0;
    max-width: 500px;
`
export const TimerAR = styled.div`
    color:#fff;
    background-color: rgb(249,86,79,0.95);
    align-items: center;
    font-weight:bold; 
    vertical-align: middle;
    display:flex;
    margin-top:40px;
    flex-direction:row;
    border-radius: 0 25px 25px 0;
    width: 450px;
    @media screen and (max-width: 480px) {
        width: 300px;
        }
`
export const TimerWrapper1 = styled.div`
    border-radius: 0 25px 25px 0;
    justify-content:center;
    vertical-align: middle;1
    margin-top:3px;
    padding:0.5em;
    text-align:center;
    @media screen and (max-width: 480px) {
        padding: 1em 0.3em;
        }
`
export const TimerWrapper = styled.div`
    border-radius: 0 25px 25px 0;
    justify-content:center;
    vertical-align: middle;
    display: grid;
    grid-template-columns:repeat(7, 1fr);
    margin-top:3px;
    padding:0.5em;
    text-align:center;
    @media screen and (max-width: 480px) {
        padding: 1em 0.3em;
        }
`
export const TimerText = styled.p`
    font-size: 1em;
`
export const StickyBarWrraper= styled.div`
    display:flex;
    justify-content: flex-end;
    
`
export const StickyBar = styled.div`
    position: fixed;
    z-index:100;
    margin-top:70px;
    border-radius: 25px 0 0 25px;
    background-color:rgb(101, 168, 198,0.95);
    width:auto;
    height:auto;
    box-sizing: border-box;
    box-shadow: -3px 3px 10px 2px rgb(0,0,0,0.6);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3,50px);
    text-align: left;
    font-weight: 400;
`

export const StickyBarText = styled(LinkR)`
    align-items: center;
    box-sizing: border-box;
    padding: 7px 15px;
    justify-content: center; 
    text-decoration: none;
    color:#fff;
    font-size: 1.4rem;
    transition: all 0.2 ease-in-out;
    cursor: pointer;
    &:hover{
        transition: all 0.8 ease-in-out;
        transition: background-color 0.2s linear;
        background:#fff;
        padding: 6px 14px;
        border: 2px solid rgb(101, 168, 198);
        color: rgb(101, 168, 198);
    }
`


export const HeroPAR = styled.p`
    margin-top: 12px;
    color: #fff;
    font-size: 18px;
    text-align: justify;
    max-width: 1000px;
    direction: rtl;
    @media screen and (max-width : 768px){
        font-size: 16px;
    }

    @media screen and (max-width : 480px){
        font-size: 14px;
    }
    @media screen and (max-width : 439px){
        font-size: 12px;
    }

`