import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  RadioProps
} from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  multilineColor: {
    color:'#000',
    alignItems: 'right',
    fontFamily:"Segoe UI",
    justifyContent: 'center',
    textAlign:'right',
    padding:'14px',
    fontWeight:"600",
    paddingLeft:"2px",
    lineHeight:"1.5"
  },
}));
const Radio2Wrapper = ({
  name,
  options,
  label,
  legend,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const classes = useStyles();
  const handleChange = evt => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
  };

  const configCheckbox = {
    ...field,
    onChange: handleChange
  };

  const configFormControl = {};
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
  }

  return (
    <FormControl {...configFormControl}>
   
      <FormLabel component="legend" className= {classes.multilineColor}>
        {legend}
      </FormLabel>
      <RadioGroup aria-label="gender" name="gender1" row style={{paddingLeft:"3px"}}>
      {Object.keys(options).map((item, pos) => {
        return (
          <>
        <RadioButtonGroup 
          value={item}
          name={name}
          color="primary"
          label={item}/>
        </>
        )
      })}
      
      </RadioGroup>
    </FormControl>
  );
};

export default Radio2Wrapper;

export function RadioButtonGroup(props) {
  const [field] = useField({
    name: props.name,
    type: "radio",
    value: props.value,
  });
  return (
    
    <FormControlLabel
      control={<Radio {...props} {...field} />}
      label={props.label}
    />
    
  );
}