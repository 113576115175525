import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'
import {Link as LinkR} from 'react-router-dom'

export const ServicesContainer = styled.div`
    
    height: 800px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1000px){
        height: 1900px;
    }
    @media screen and (max-width: 480px){
        height:1800px;
    }
    @media screen and (max-width: 380px){
        height:2200px;
        margin-top:-150px;
        }
`
export const ServicesWrapper = styled.div`
    max-width: 1000px;
    display: grid;
    margin:  0 auto;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content:center;
    align-items: center;
    grid-gap: 16px;
    padding: 0 -50px;


    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr ;
        padding: 0;
    }
    
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 ;
    }
`

export const ServicesWrapper2 = styled.div`
    max-width: 1000px;
    display: grid;
    margin:  0 auto;
    grid-template-columns: 1fr;
    justify-content:center;
    align-items: center;
    grid-gap: 16px;
    padding: 0 -50px;


    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr;
        padding: 0;
    }
    
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 ;
    }
`
export const ServicesWrapper1 = styled.div`
    
    display: grid;
    margin:  0 ;
    max-width1920px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 0;
    padding: 0;
    align-items:center;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr ;
        padding: 0;
    }
    
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 ;
    }
`
export const NavBtnLink1 = styled(LinkS)`
    font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
    background: #0075B4;
    align-items: center;
    border-radius: 0 0 25px 25px;
    margin:0 15px;
    text-align:center;
    white-space: nowrap;
    padding: 13px 22px;
    color: #fff;
    font-weight:500;
    height: 45px;
    width:210;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    @media screen and (max-width: 1000px){
        width:auto;
    }
    @media screen and (max-width: 786px){
        margin: 15px 20px;
        font-size: 12px;
    }
&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 11px 22px;
    border: 2px solid #0075B4;
    color: #0075B4;
}
`

export const NavBtnLink10 = styled(LinkR)`
    font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
    background: #0075B4;
    align-items: center;
    border-radius: 0 0 25px 25px;
    margin:0 15px;
    text-align:center;
    white-space: nowrap;
    padding: 13px 22px;
    color: #fff;
    font-weight:500;
    height: 45px;
    width:210;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    @media screen and (max-width: 1000px){
        width:auto;
    }
    @media screen and (max-width: 786px){
        margin: 15px 20px;
        font-size: 12px;
    }
&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 11px 22px;
    border: 2px solid #0075B4;
    color: #0075B4;
}
`

export const NavBtnLink2 = styled(LinkS)`
font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
background: #0075B4;
align-items: center;
margin:0 15px;
text-align:center;
white-space: nowrap;
border-radius: 0 0 25px 25px;
padding: 13px 22px;
color: #fff;
font-weight:500;
height: 45px;
width:210;
font-size: 14px;
outline: none;
border: none;
cursor: pointer;
transition: all 0.8 ease-in-out;
background-color 0.8s linear;;
text-decoration:none;
@media screen and (max-width: 1000px){
    width:auto;
}
@media screen and (max-width: 786px){
    margin: 15px 20px;
    font-size: 12px;
}
`
export const ServicesCard = styled.div`
    height: 463px;
    flex-direction: column;
    justify-content: center ;
    border-radius: 10px;
    text-align: center;
    background:#EFEBCE;
    padding: 30px;
    margin-top:100px;
    padding-right:60px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    position:relative;
    z-index:1;
    &:hover{
        transform scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    @media screen and (max-width: 1000px){
        postion:relative;
        z-index:-1;
        margin:20px;
        padding:20px;
        width:400px;
        height: 456px;
    }
    
    @media screen and (max-width: 480px){
        margin:10px;
        width:350px;
    }
    @media screen and (max-width: 380px){
        margin:10px;
        width:280px;
    }
    

`

export const ServicesIcon= styled.img`
    height: 250px;
    width:250px;
    margin-bottom: 10px;

`

export const ServicesH1 = styled.h1`
    font-size: 1.3rem;
    color:#393939;
    margin-bottom: 64px
    @media screen and (max-width: 480px){
        font-size: 1rem;
    }
    @media screen and (max-width: 1000px){
        padding:20px;
    }
`


export const ServicesP = styled.p`
    font-size: 1rem;
    text-align: justify;

`
export const ServicesPAR = styled.p`
    font-size: 1rem;
    text-align: justify;
  direction: rtl;

`

export const ServicesPfAR = styled.b`
    color:#fff;
  direction: rtl;

`
export const ServicesPf = styled.b`
    color:#fff;

`
export const ServicesCard1 = styled.div`
    height: 456px;
    margin-left:-60px;
    flex-direction: column;
    justify-content: center ;
    border-radius: 10px;
    text-align: center;
    background:#B4CECB;
    padding: 30px;
    margin-top:50px;
    padding-right:60px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    position:relative;
    z-index:2;
    &:hover{
        transform scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    @media screen and (max-width: 1000px){
        postion:relative;
        z-index:-1;
        margin:20px;
        width:400px;
        height: 456px;
    }
    @media screen and (max-width: 480px){
        margin:10px;
        width:350px;
        padding:20px;
    }
    @media screen and (max-width: 380px){
        margin:10px;
        width:280px;
        height:500px;
    }
`
export const ServicesCard2 = styled.div`
    height: 456px;
    flex-direction: column;
    justify-content: center ;
    border-radius: 10px;
    text-align: center;
    background:#65A8C6;
    padding: 30px;
    margin-left:-60px;
    margin-top:0px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    position:relative;
    z-index:3;
    &:hover{
        transform scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    @media screen and (max-width: 1000px){
        postion:relative;
        z-index:-1;
        margin:20px;
        width:400px;
        height: 456px;
    }
    @media screen and (max-width: 480px){
        margin:10px;
        width:350px;
        padding:20px;
    }
    @media screen and (max-width: 380px){
        margin:10px;
        width:280px;
        height:520px;
    }
`

export const ServicesCardAR = styled.div`
    height: 463px;
    flex-direction: column;
    justify-content: center ;
    border-radius: 10px;
    text-align: center;
    background:#EFEBCE;
    padding: 30px;
    margin-top:100px;
    padding-left:60px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    position:relative;
    z-index:1;
    &:hover{
        transform scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    @media screen and (max-width: 1000px){
        postion:relative;
        z-index:-1;
        margin:20px;
        padding:20px;
        width:400px;
        height: 456px;
    }
    
    @media screen and (max-width: 480px){
        margin:10px;
        width:350px;
    }
    @media screen and (max-width: 380px){
        margin:10px;
        width:280px;
    }
    

`

export const ServicesCard1AR = styled.div`
    height: 456px;
    margin-right:-60px;
    flex-direction: column;
    justify-content: center ;
    border-radius: 10px;
    text-align: center;
    background:#B4CECB;
    padding: 30px;
    margin-top:50px;
    padding-left:60px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    position:relative;
    z-index:2;
    &:hover{
        transform scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    @media screen and (max-width: 1000px){
        postion:relative;
        z-index:-1;
        margin:20px;
        width:400px;
        height: 456px;
    }
    @media screen and (max-width: 480px){
        margin:10px;
        width:350px;
        padding:20px;
    }
    @media screen and (max-width: 380px){
        margin:10px;
        width:280px;
        height:500px;
    }
`
export const ServicesCard2AR = styled.div`
    height: 456px;
    flex-direction: column;
    justify-content: center ;
    border-radius: 10px;
    text-align: center;
    background:#65A8C6;
    padding: 30px;
    margin-right:-60px;
    margin-top:0px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    position:relative;
    z-index:3;
    &:hover{
        transform scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    @media screen and (max-width: 1000px){
        postion:relative;
        z-index:-1;
        margin:20px;
        width:400px;
        height: 456px;
    }
    @media screen and (max-width: 480px){
        margin:10px;
        width:350px;
        padding:20px;
    }
    @media screen and (max-width: 380px){
        margin:10px;
        width:280px;
        height:520px;
    }
`