import './App.css';
import Landing from './Pages'
import FormCivic from './Pages/FormCivic'
import FormTech from './Pages/FormTech'
import Guideline from './Pages/GuidelineEN'
import frequentlyAskedQuestions from './Pages/frequentlyAskedQuestions'

import LandingAR from './Pages/Arabic'
import FormCivicAR from './Pages/Arabic/FormCivic'
import GuidelineAR from './Pages/GuidelineAR'
import FormTechAR from './Pages/Arabic/FormTech'
import frequentlyAskedQuestionsAR from './Pages/Arabic/frequentlyAskedQuestions'

import LandingFR from './Pages/French'
import FormCivicFR from './Pages/French/FormCivic'
import GuidelineFR from './Pages/GuidelineFR'
import FormTechFR from './Pages/French/FormTech'
import frequentlyAskedQuestionsFR from './Pages/French/frequentlyAskedQuestions'
import ScrollToTop from './Pages/ScrollToTop'
import {BrowserRouter as Router, Switch, Route}from 'react-router-dom'

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/FormCivic" component={FormCivic} exact/>
          <Route path="/FormTech" component={FormTech} exact/>
          <Route path="/Guidelines" component={Guideline} target="_blank"  exact/>
          <Route path="/FAQ" component={frequentlyAskedQuestions} exact/>
        
          <Route path="/AR" component={LandingAR} exact/>
          <Route path="/AR/FormCivic" component={FormCivicAR} exact/>
          <Route path="/AR/FormTech" component={FormTechAR} exact/>
          <Route path="/AR/Guidelines" component={GuidelineAR} target="_blank" exact/>
          <Route path="/AR/FAQ" component={frequentlyAskedQuestionsAR} exact/>
          
          <Route path="/FR" component={LandingFR} exact/>
          <Route path="/FR/FormCivic" component={FormCivicFR} exact/>
          <Route path="/FR/FormTech" component={FormTechFR} exact/>
          <Route path="/FR/Guidelines" component={GuidelineFR} exact target="_blank"/>
          <Route path="/FR/FAQ" component={frequentlyAskedQuestionsFR} exact/>
          <Landing/>
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
