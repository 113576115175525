import React, { useState} from 'react'
import {Button} from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow,Img1, Column1,List,LiList, Column2,ServicesPf, Column3, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, Img, ImgWrap } from '../InfoSection2/InfoElements'
 
const InfoSection2 = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, description2, buttonLabel,
     img, alt, primary, dark, dark2}) => {


    return (
        <>
            <InfoContainer lightBg={lightBg} id={"who"}>
                <InfoWrapper>
                <Heading>Qui peut y participer ?</Heading>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <List>
                                <LiList><Img1 src={require('../../images/Frame7.png').default}/>Être ressortissant et / ou résident
                                    de l’un des pays suivants : Algérie, Égypte, Jordanie, Liban, Libye, Mauritanie, Maroc, Palestine, Syrie et Tunisie. 
                                </LiList>
                                <LiList><Img1 src={require('../../images/Frame8.png').default}/>Être un acteur de la société civile,
                                    un développeur IT ou bien un graphiste.</LiList>
                                <LiList><Img1 src={require('../../images/Group-30.png').default}/>Être un élément activement
                                    engagé dans sa communauté, doté d’un fort impact sur le terrain.</LiList>
                            </List>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={require('../../images/develop.png').default} alt={"Postulez pour le hackathon du Union European"} />
                            </ImgWrap>
                        </Column2>
                        <Column3>
                            <List>
                                <LiList><Img1 src={require('../../images/Frame7.png').default}/>Avoir une expertise et une expérience dans le domaine des outils TIC, de la transparence et responsabilité publiques, du renforcement de la communauté ou bien de la citoyenneté active.</LiList>
                                <LiList><Img1 src={require('../../images/Frame7.png').default}/>Faire partie d’une organisation capable de gérer, pour le moins, des opérations à petite échelle (condition non-obligatoire pour les développeurs IT et graphistes).</LiList>
                                <LiList><Img1 src={require('../../images/Group-30.png').default}/>Avoir une bonne maîtrise de l’anglais et / ou de l’arabe et / ou du français.</LiList>
                            </List>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
                
            </InfoContainer>
           </>
    )
}

export default InfoSection2
