import React, {useState} from 'react';
import {HeroContainer, HeroBg,Img,ImgWrap, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward,ArrowRight, InfoRow, Column1, Column2 } from './LastSecElements'
import {Button} from '../ButtonElement'
const LastSec = () => {
const [hover, setHover] = useState(false)

const onHover = () =>{
    setHover(!hover)
}

    return (
        <HeroContainer id="win">
            
            <HeroContent>
            <HeroH1>“Hack To Act” Award</HeroH1>
                <InfoRow>
                    <Column1>
                            <ImgWrap>
                                <Img src={require('../../images/Winner1.svg').default} alt={"Hackathon Med Dialogue Union European"} />
                            </ImgWrap>
                    </Column1>
                    <Column2>
                        <HeroP>
                        At the end of the “Hack to act” Hackathon, mentors and
                        participants will vote for the 3 best digital solutions
                        pitched. <b>During 6 months </b>from the completion of the
                        Hackathon, winner “Hack Teams” will be granted technical
                        and <b>financial support up to 10.000 € </b>to further develop, test
                        and launch their digital solution in the course of <b>the year
                        2021/2022.</b>
                        </HeroP> 
                    </Column2>
                </InfoRow>
            </HeroContent>
        </HeroContainer>
    )
}

export default LastSec
