import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  CheckboxProps,
  Typography
} from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  multilineColor: {
    color:'#000',
    alignItems: 'right',
    justifyContent: 'center',
    textAlign:'right',
    padding:'14px',
    fontFamily:"Segoe UI",
    fontWeight:'600',
    paddingLeft:"5px",
    lineHeight:"1.5",
  },
}));

const CheckboxWrapper = ({
  name,
  options,
  label,
  legend,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const classes = useStyles();

  const handleChange = evt => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
  };

  const configCheckbox = {
    ...field,
    onChange: handleChange
  };

  const configFormControl = {};
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    configFormControl.helperText = meta.error;
  }
  return (
    <FormControl {...configFormControl}>
       <FormLabel component="legend" className= {classes.multilineColor}>
        {legend}
      </FormLabel>
      <FormGroup row style={{paddingLeft:"5px"}}>
      {Object.keys(options).map((item, pos) => {
        return (
        <>
        <MyCheckbox
          color="primary"
          name={name}
          label={item}
          value={item}
          />
          
        </>
        )
      })}
      </FormGroup>
      <Typography style={{color:"red"}}>{configFormControl.helperText}</Typography>
    </FormControl>
  );
};

export default CheckboxWrapper;

export interface MyCheckboxProps extends CheckboxProps {
  name: string;
  value?: string | number;
  label?: string;
}

export function MyCheckbox(props: MyCheckboxProps) {
  const [field] = useField({
    name: props.name,
    type: 'checkbox',
    value: props.value
  });
  return (
    <FormControlLabel
      control={<Checkbox {...props} {...field} />}
      label={props.label}
    />
  );
}