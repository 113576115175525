import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {HeroH112} from '../HeroSection/HeroSectionElements';
import Header from './headerFR';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '30px 0 30px 0',
    
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    textAlign:'center',
    alignContent:'center',
    display:'block',
    alignItems:'center'
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    textAlign:'center',
    padding:'5px'
  },
  paper:{
    backgroundColor:'#EE6A88',
    color:'#fff',
    textAlign:'center',
    alignContent:'center',
    alignItems:'center',
    "& .MuiAccordionSummary-content": { display: "block" }
  }
}));

const FaqFR = () => {
  const classes = useStyles();

  return (
    <>
        <Header/>
    <div className={classes.root}>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.paper}
        >
          <Typography className={classes.heading}>Qu'est-ce que l'Idéathon «Espace civique numérique et nouvelles technologies pour la démocratie»?</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify"}}>
            Lors de la première étape de l'initiative Hackathon «Hack to act», les candidats des OSCs présélectionnés, ayant postulé avec une idée de solution technologique, participeront à un atelier en ligne d’Idéathon (génération d’idées) et de renforcement des capacités, intitulé : <b>« Espace civique numérique et nouvelles technologies pour la démocratie»,</b> qui se tiendra fin mai 2021. Au cours de cet atelier, les participants seront formés et coachés afin de développer plus leurs idées de projets, avant de les présenter. Suite à une session de pitching,<b> jusqu’à 15 solutions technologiques seront sélectionnées </b>pour participer au Hackathon «Hack to act», qui se tiendra  à Tunis (Tunisie).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>En quoi consiste la dernière session de pitching du Hackathon ?</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify"}}>
            Pendant le Hackathon «Hack to act», jusqu’à 15 «Equipes de hackeurs» travailleront et s'affronteront pour présenter leurs projets à leurs pairs et à leurs mentors. Pour cela, chaque «équipe de hackeurs» devra préparer un prototype fonctionnel de sa solution, afin de présenter ses principales caractéristiques et de donner une vision claire de son fonctionnement au public. Suite à cette session de pitching, 3 solutions digitales seront sélectionnées pour bénéficier d'un soutien technique et financier pouvant atteindre les 10.000 € pour chaque équipe.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>Dois-je nécessairement être membre d'une organisation de la société civile pour postuler au Hackathon avec une idée de solution technologique ?</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify"}}>
            Non, vous n’y êtes pas tenu. Il est cependant nécessaire que vous décriviez votre parcours et votre expérience en tant que citoyen actif engagé pour la démocratie. Cela peut consister en une expérience de volontariat, une participation à la vie de votre communauté ou à des initiatives médiatiques, l’appartenance à des organisations étudiantes, à des mouvements civiques, à des groupes politiques, etc.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>Je suis un activiste de la société civile, dois-je avoir une équipe de hackeurs complète, comprenant un un développeur IT et un graphiste, au moment de postuler avec ma solution technologique ? Si non, comment les équipes de hackeurs seront-elles constituées ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{textAlign:"justify"}}>
            Chaque activiste émanant d’une OSC doit postuler avec son propre outil TIC / solution technologique. Pour le moment, il ne sera pas demandé des candidats de disposer d’une équipe de hackeurs déjà constituée. Cependant, quelques réunions en ligne seront dédiées à la constitution d’équipes de hackeurs, une fois de l’Idéathon achevé. La constitution des équipes de hackeurs se fera de manière participative : chaque activiste émanant d’une OSC doit convaincre les développeurs et grapihistes qui auront postulé de manière indépendante, de rejoindre son équipe (à raison d’un développeur + un graphister par équipe). Ces sessions en ligne seront donc une occasion pour les activistes des OSC de présenter individuellement leurs solutions et aussi pour les développeurs et les graphistes de choisir la solution dans laquelle ils voudraient être impliqués / l’équipe à laquelle ils souhaiteraient appartenir (via un processus de vote rapide en ligne) .
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br></br>
<Typography className={classes.heading2}>Si vous avez des questions, n’hésitez pas à nous écrire sur<b> hela.bensalah@particip.com </b>. Nous publierons votre question dans cette section.</Typography>
      
    </div>
    </>
  );
}

export default FaqFR
