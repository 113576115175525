import React, { useState} from 'react'
import {Button} from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow,Img1, Column1,List,LiListAR, Column2,ServicesPf, Column3, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, Img, ImgWrap } from '../InfoSection2/InfoElements'
 
const InfoSection2 = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, description2, buttonLabel,
     img, alt, primary, dark, dark2}) => {


    return (
        <>
            <InfoContainer lightBg={lightBg} id={"who"}>
                <InfoWrapper>
                <Heading>من يمكنه التقدم بطلب؟</Heading>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <List>
                                <LiListAR><Img1 src={require('../../images/Frame7.png').default}/>أن يكون لديهم خبرة وتجربة في أدوات التقنيات الحديثة والشفافية العامة والمساءلة، أو بناء المجتمع، أو المواطنة النشطة. 
                                </LiListAR>
                                <LiListAR><Img1 src={require('../../images/Frame8.png').default}/>
                                    أن يكونوا جزءًا من منظمة يمكنها على الأقل إدارة عمليات صغيرة الحجم (ليست إلزاميًا بالنسبة لمقدمي طلبات تكنولوجيا المعلومات والمصممين).
                                </LiListAR>
                                <LiListAR><Img1 src={require('../../images/Group-30.png').default}/>
                                    أن يجيدوا اللغة الإنجليزية و/ أو العربية و / أو الفرنسية.
                                </LiListAR>
                            </List>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={require('../../images/develop.png').default} alt={"هاكاثون الاتحاد الأوروبي"} />
                            </ImgWrap>
                        </Column2>
                        <Column3>
                            <List>
                                <LiListAR><Img1 src={require('../../images/Frame7.png').default}/>أن يكونوا مواطنين و/ أو مقيمين في إحدى البلدان التالية: الجزائر، مصر، الأردن، لبنان، ليبيا، موريتانيا، المغرب، فلسطين، تونس، وسوريا.</LiListAR>
                                <LiListAR><Img1 src={require('../../images/Frame7.png').default}/>أن يكونوا فاعلين في المجتمع المدني،  مبرمجين أو مصممين.</LiListAR>
                                <LiListAR><Img1 src={require('../../images/Group-30.png').default}/>أن يكونوا مشاركين بشكل نشط في مجتمعاتهم وأن يثبتوا أن لهم تأثيرًا على أرض الواقع.</LiListAR>
                            </List>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
                
            </InfoContainer>
           </>
    )
}

export default InfoSection2
