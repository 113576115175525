import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md'

export const HeroContainer = styled.div`
       background: #EFEBCE;
       display: flex;
       justify-content: center;
       padding: 0 30px;
       height: 560px;
       position: relative;
       z-index: -1; 

       
        @media screen and (max-width : 768px){
            height: 1000px;
        }
    
        @media screen and (max-width : 480px){
            height: 1100px;
        }
`

export  const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right:0;
    bottom:0;
    left:0;
    width:100%;
    height:100%;
    overflow: hidden;
`

export const VideoBg = styled.img`
    width: 100%;
    height:100%;
    -o-object-fit: cover;
    object-fit: cover;
`

export const HeroContent =styled.div`
    z-index : 6;
    max-width: 1200px;
    position: absolute;
    padding: 8px 20px;
    display : flex;
    flex-direction: column;
    align-items: center;
`

export const HeroH1 = styled.div`
    color: #EE718E;
    margin-top:20px;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size:48px;
    font-weight:bold;
    border-raduis:50px;
    text-align: center;

    @media screen and (max-width : 768px){
        font-size: 40px;
    }

    @media screen and (max-width : 480px){
        font-size: 32px;
    }
`

export const HeroP = styled.p`
    color: #000;
    margin-top:-30px;
    font-size: 22px;
    text-align: justify;
    align-items: center;
    max-width: 600px;

    @media screen and (max-width : 768px){
        font-size: 20 px;
    }

    @media screen and (max-width : 480px){
        font-size: 18 px;
    }

`

export const HeroPAR = styled.p`
    color: #000;
    margin-top:-30px;
    font-size: 22px;
    text-align: justify;
    align-items: center;
    max-width: 600px;
    direction:rtl;
    @media screen and (max-width : 768px){
        font-size: 20 px;
    }

    @media screen and (max-width : 480px){
        font-size: 18 px;
    }

`

export const HeroBtnWrapper =styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`



export const InfoRow= styled.div`
       display: grid;
       margin:30px 0 50px 0;
       grid-auto-columns: minmax(auto, 1fr);
       grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'`: `'col1 col2'`)};

       @media screen and (max-width: 768px) {
           grid-template-areas: ${({imgStart}) => (imgStart ? `'col1 col1' 'col2 col2'`: `'col1 col1' 'col2 col2'`)};
       }
`

export const Column1 = styled.div`
       margin-bottom: 15px;       
       padding: 0 15px;
       align-items: center;
       grid-area: col1;
`
export const Column2 = styled.div`
       margin-top: 120px;
       align-items: start;
       grid-area: col2;
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
       width: 100%;
       padding-right: 0;
`
