import React from 'react'
import {Button} from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2,ServicesPf, Column3, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, Img, ImgWrap } from '../Shortlist/ShortElements'
 
const Shortlist = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, description2, buttonLabel,
     img, alt, primary, dark, dark2}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={"online"}>
                <InfoWrapper>
                <Heading> Les Candidats des OSCs Pré-sélectionnés participeront<br></br> à un Idéathon avant le Hackathon</Heading>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine> {topLine}</TopLine>
                                <Subtitle>Comme première étape de l’initiative Hackathon «Hack to act», les candidats pré-sélectionnés provenant des OSCs, qui ont postulé avec une idée de solution technologique, participeront à un atelier d’Idéathon (Génération d’Idées) et de renforcement de capacités, qui se tiendra en ligne sous le thème :
                                <ServicesPf> “Espace civique numérique et nouvelles technologies pour la démocratie” à la fin du mois de mai 2021. 
                               </ServicesPf>
                            </Subtitle>
                                
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={require('../../images/bulb.png').default} alt={"Ideé Pour un hackathon"} />
                            </ImgWrap>
                        </Column2>
                        <Column3>
                            <TextWrapper>
                                <TopLine> {topLine}</TopLine>
                                <Subtitle darkText={darkText}>
                                   Au cours de cet atelier, les participants seront initiés aux compétences indispensables à la réalisation de leurs solutions, entraînés et coachés afin de développer davantage leurs idées de solutions technologiques avant de les présenter. Suite à une séance de pitching, jusqu’à 
                                    <ServicesPf> 15 solutions technologiques seront sélectionnées</ServicesPf> pour participer au Hackathon "Hack to act", qui se tiendra à Tunis (Tunisie)</Subtitle>
                            </TextWrapper>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
                
            </InfoContainer>
           </>
    )
}

export default Shortlist
