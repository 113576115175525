import React, {useRef,useEffect,useState} from 'react';
import {HeroBg,Img,ImgWrap,Timer, TimerText,TimerWrapper1,ImgBg, TimerWrapper,StickyBarWrraper, StickyBar,StickyBarText, VideoBg, HeroContent, HeroH11, HeroP, HeroBtnWrapper, InfoRow, Column1, Column2 } from '../HeroSection/HeroSectionElements'
import {HeroContainer} from './FrenchElements'
import {NavBtnLink1,NavBtn} from '../Navbar/NavbarElemnts'
const HeroSection = () => {
const [hover, setHover] = useState(false)

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [timerDays1, setTimerDays1] = useState('00');
    const [timerHours1, setTimerHours1] = useState('00');
    const [timerMinutes1, setTimerMinutes1] = useState('00');
    const [timerSeconds1, setTimerSeconds1] = useState('00');
    let interval= useRef();

    const startTimer =() => {
        const countdownDate= new Date('April 22, 2021 22:00:00');
        const countdownDate1= new Date('May 16, 2021 22:00:00');

        interval=setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate- now;
            const days= Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours= Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000*  60));
            const seconds= Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                
            };
            
            const distance1 = countdownDate1- now;
            const days1= Math.floor(distance1 / (1000 * 60 * 60 * 24));
            const hours1= Math.floor((distance1 % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes1= Math.floor((distance1 % (1000 * 60 * 60)) / (1000*  60));
            const seconds1= Math.floor((distance1 % (1000 * 60)) / 1000);
            if (distance1 < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays1(days1);
                setTimerHours1(hours1);
                setTimerMinutes1(minutes1);
                setTimerSeconds1(seconds1);
            }
        }, 1000);
    };
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });
const onHover = () =>{
    setHover(!hover)
}

    return (
        <HeroContainer id="Hero">
            <HeroBg>
                <ImgBg src={require('../../images/herobackfr1.png').default} alt={"Hackathon Hack To Act"} />
            </HeroBg>
            <StickyBarWrraper>
            <StickyBar>
                <StickyBarText to='/'>EN</StickyBarText>
                <StickyBarText to='/FR'>FR</StickyBarText>
                <StickyBarText to='/AR'>عر</StickyBarText>
            </StickyBar>
            </StickyBarWrraper>
            <HeroContent>
                <InfoRow>
                    <Column1>
                    <HeroH11>Hackathon <b>#HackToAct</b></HeroH11>
                        <HeroP>
                        Le Hackathon <b>«Hack to act» se tiendra du 8 au 11 juillet 2021 à Tunis:</b> le Programme « Med Dialogue for Rights and Equality » offre un espace
                        civique digital à jusqu’à <b>45 jeunes activistes de la société civile, développeurs IT et graphistes,</b> provenant des pays du <b>Machrek et du
                        Maghreb.</b> Regroupés en <b>« Equipes de Hackeurs » (Hack teams), ils auront l’occasion de créer de nouveaux liens à travers les frontières
                        et à concevoir des solutions technologiques efficaces visant à développer une démocratie durable, promouvoir les droits de l’homme
                        et contribuer aux réformes et changements démocratiques dans leurs pays d’origine.</b>
                        </HeroP>
                       <HeroP>
                        <b>A l’issue de ce Hackathon, 3 « Equipes de Hackeurs » seront </b> 
                        sélectionnées et bénéficieront d'un <b>soutien technique et financier
                        pouvant aller jusqu'à 10 000 €</b> , pour développer plus leurs solutions
                        technologiques, les tester avant de les lancer.
                       </HeroP>
                        
                    </Column1>
                    
                    <Timer>
                       <TimerWrapper1>
                            <TimerText>Date Limite<br></br><small>Activistes de la société civile</small></TimerText>
                        </TimerWrapper1> 
                        <TimerWrapper>
                            <TimerText>{timerDays}<br></br><small>Jours</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerHours}<br></br><small>Heures</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerMinutes}<br></br><small>Minutes</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerSeconds}<br></br><small>Secondes</small></TimerText>
                       </TimerWrapper> 
                    </Timer>
                    <Timer>
                       <TimerWrapper1>
                            <TimerText>Date Limite<br></br><small>Les Développeurs IT et Graphistes</small></TimerText>
                        </TimerWrapper1> 
                        <TimerWrapper>
                            <TimerText>{timerDays1}<br></br><small>Jours</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerHours1}<br></br><small>Heures</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerMinutes1}<br></br><small>Minutes</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerSeconds1}<br></br><small>Secondes</small></TimerText>
                       </TimerWrapper> 
                    </Timer>
                    
                </InfoRow>
            </HeroContent>
            <NavBtn>
                            <NavBtnLink1 to="apply"
                                smooth={true}
                                duration={1000}
                                spy={true}
                                exact='true'
                                offset={0}
                                activeClass="active">
                                    Postulez maintenant !
                            </NavBtnLink1>
                    </NavBtn>
        </HeroContainer>
    )
}

export default HeroSection
