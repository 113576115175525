import React, {useState} from 'react';
import {HeroContainer, HeroBg,Img,ImgWrap, VideoBg, HeroContent, HeroH1, HeroPAR, HeroBtnWrapper, ArrowForward,ArrowRight, InfoRow, Column1, Column2 } from '../LastSec/LastSecElements'
import {Button} from '../ButtonElement'
const LastSec = () => {
const [hover, setHover] = useState(false)

const onHover = () =>{
    setHover(!hover)
}

    return (
        <HeroContainer id="win">
            
            <HeroContent>
            <HeroH1>جائزة " Hack to Act " </HeroH1>
                <InfoRow>
                    <Column1>
                            <ImgWrap>
                                <Img src={require('../../images/Winner1.svg').default} alt={"جائزة الهاكاثون"} />
                            </ImgWrap>
                    </Column1>
                    <Column2>
                        <HeroPAR>
                        في نهاية هاكاثون " Hack to Act"، سيصوت المرشدون والمشاركون لأفضل 3 أدوات رقمية. وفي<b> خلال 6 أشهر</b> من الانتهاء من الهاكاثون سيتم منح الفائزين من "فرق البرمجة "Hack Teams دعمًا تقنيًا وماليًا<b> يصل إلى 10 آلاف يورو</b> لاختيار ومواصلة تطوير وإطلاق الحل الرقمي الخاص بهم خلال<b> العام    2021/2022.</b>

                        </HeroPAR> 
                    </Column2>
                </InfoRow>
            </HeroContent>
        </HeroContainer>
    )
}

export default LastSec
