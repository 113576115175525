import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa'
import {Link as LinkR} from 'react-scroll'

export const SidebarContainer = styled.aside`
    
    position: absolute;
    margin-top:270px;
    padding:15px;
    z-index: 999;
    width: 100%;
    height: 85%;
    background: #fff;
    display: block;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-200%')};
`

export const CloseIcon = styled(FaTimes)`
    color: #000;
    font-size: 1.6rem;
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 2rem;
    background: transparent;
    font-size: 2.4rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.div`
    background: #fff;
`
export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8,60px);
    text-align: left;
    font-weight: 400;
    line-height: 16px;
    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(8,40px);
        }
`
export const SidebarLink = styled.a`
font-family:"alwyn-new-rounded-web",sans-serif;
    color: #0076B5;
    display: flex;
    font-weight: bold;
    line-height: 16px;
    align-items: center;
    justify-content: start; 
    text-decoration: none;
    list-style: none;
    font-size: 0.8rem;
    transition: all 0.2 ease-in-out;
    cursor: pointer;

    &:hover{
        transition: all 0.2 ease-in-out;
        color: #01bf71;
    }
    `
    export const SidebarLinkR = styled(LinkR)`
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center; 
    text-decoration: none;
    list-style: none;
    font-size: 1.5rem;
    transition: all 0.2 ease-in-out;
    cursor: pointer;

    &:hover{
        transition: all 0.2 ease-in-out;
        
        color: #01bf71;
    }
    `
export const SidebarBtnWrap = styled.div`
    display: flex;
    justify-content: center; 
`
export const SidebarRoute = styled(LinkR)`
    border-radius: 50px;
    background: #01bf71;
    white-space: nowrap;
    padding: 16px 64px;
    outline: none;
    border: none;
    color: #010606;
    cursor:pointer;
    transition: 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2 ease-in-out;
        background: #fff;
        color: #01bf71;
    }
`



export const NavBtnLink1 = styled.button`
font-family:"alwyn-new-rounded-web",sans-serif;
    Border-Radius: 50px;
    font-weight: bold;
    background: #0075B4;
    align-items: center;
    white-space: nowrap;
    padding: 8px 30px;
    color: #fff;
    height: 35px;
    width:130px;
    font-size: 13px;
    outline: none;
    border: none;
    width:100%;
    margin-top:15px;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;

&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    color: #0075B4;
    padding: 8px 30px;
}
`