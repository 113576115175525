import styled from 'styled-components'

export const InfoContainer = styled.div`
        color:#000; 
        height: auto;      
       @media screen and (max-width: 768px) {
           padding: 100px 0;
           height: 1300px;
       }
       @media screen and (max-width: 480px) {
              padding: 50px 0;
              height: 1200px;
          }
       `

export const InfoWrapper = styled.div`
       display: grid;
       z-index: 1;
       height: 500px;
       width: 100%;
       max-wdith: 1100px;
       margin-right: auto;
       align-items:center;
       margin-left: auto;
       padding: 0 24px;
       justify-content: center;
       @media screen and (max-width: 768px) {
              height: 700px;
       }
`

export const InfoRow= styled.div`
       display: grid;
       grid-auto-columns: minmax(auto, 1fr);
       align-items: center;
       grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1 col3'`: `'col1 col2 col3'`)};

       @media screen and (max-width: 768px) {
           grid-template-areas: ${({imgStart}) => (imgStart ? `'col1 col1 col1' 'col2 col2 col2' 'col3 col3 col3'`: `'col1 col1 col1' 'col2 col2 col2' 'col3 col3 col3'`)};
       }
`

export const Column1 = styled.div`
       margin-bottom: 15px;
       padding: 0 15px;
       grid-area: col1;
       justify-content: center;
       align-items:center
`
export const Column2 = styled.div`
       margin-bottom: 15px;
       padding: 0px;
       grid-area: col2;
       justify-content: center;
       align-items:center;

`
export const Column3 = styled.div`
       margin-bottom: 15px;
       padding: 0 15px;
       grid-area: col3;
`
export const TextWrapper = styled.div`
       max-width: 540px;
`

export const TopLine = styled.p`
       color: #01bf71;
       font-size: 16px;
       line-height: 1.3;
       font-weight: 700;
       letter-spacing: 1.4px;
       text-transform: uppercase;
       margin-bottom: 16px;
`

export const Heading = styled.h1`
       color:#7AAFC6;
       font-size: 38px;
       line-height: 1.1;
       font-weight: bold;
       text-align:center;
       margin-bottom: 24px;

       @media screen and (max-width: 480px){
           font-size: 28px;
       }
`
export const HeadingAR = styled.h1`
       color:#7AAFC6;
       font-size: 38px;
       direction:rtl;
       line-height: 1.1;
       font-weight: bold;
       text-align:center;
       margin-bottom: 24px;

       @media screen and (max-width: 480px){
           font-size: 28px;
       }
`
export const Subtitle =styled.p`
       max-width: 440px;
       margin-bottom: 35px;
       font-size: 18px;
       text-align: justify;
       line-height: 24px;
       color: ${({darkText}) => (darkText ? '#010606':'#000')};
`
export const SubtitleAR =styled.p`
       max-width: 440px;
       margin-bottom: 35px;
       direction: rtl;
       font-size: 18px;
       text-align: justify;
       line-height: 24px;
       color: ${({darkText}) => (darkText ? '#010606':'#000')};
`
export const BtnWrap = styled.div`
       display: flex;
       justify-content: flex-start;
`

export const ImgWrap = styled.div`
    max-width: 555px;
    width: 70%;
`

export const Img = styled.img`
       padding: 0 0 0 80px;
       @media screen and (max-width: 480px){
              padding: 0 0 0 20px;
          }
`
export const ServicesPf = styled.b`
    color:#EE6A88;
    font-weight:bold;
`
export const ServicesPfAR = styled.b`
    color:#EE6A88;
    direction:rtl;
    font-weight:bold;
`