import React, {useState} from 'react';
import {HeroContainer, HeroBg,Img,ImgWrap, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward,ArrowRight, InfoRow, Column1, Column2 } from '../LastSec/LastSecElements'
import {Button} from '../ButtonElement'
const LastSec = () => {
const [hover, setHover] = useState(false)

const onHover = () =>{
    setHover(!hover)
}

    return (
        <HeroContainer id="win">
            
            <HeroContent>
            <HeroH1>Prix «Hack to Act»</HeroH1>
                <InfoRow>
                    <Column1>
                            <ImgWrap>
                                <Img src={require('../../images/Winner1.svg').default} alt={"Hackathon Med Dialogue Prix 10000 euro"} />
                            </ImgWrap>
                    </Column1>
                    <Column2>
                        <HeroP>
                        À l’issue du Hackathon «Hack to act», les mentors et les participants voteront pour les 3 meilleures solutions technologiques proposées. <b>Pendant les 6 mois</b> suivant le Hackathon, les Équipes de Hackeurs gagnantes profiteront d’un soutien technique et se verront attribuer un <b>soutien financier pouvant</b> aller jusqu’à<b> 10.000 €</b>, afin de poursuivre le  développement de leur solution technologique, la tester avant de la lancer, au cours de l’année <b>2021/2022.</b>

                        </HeroP> 
                    </Column2>
                </InfoRow>
            </HeroContent>
        </HeroContainer>
    )
}

export default LastSec
