import React, { useState, useEffect }from 'react'
import Icon1 from '../../images/Powerful.svg'
import Icon2 from '../../images/undraw_Social_networking_re_i1ex.svg'
import Icon3 from '../../images/undraw_winners_ao2o(1).svg'
import{ServicesContainer,NavBtnLink1 ,NavBtnLink2,NavBtnLink10,ServicesWrapper2,ServicesWrapper1, ServicesH1, ServicesCard,ServicesCard1,ServicesCard2, ServicesWrapper, ServicesP,ServicesPf, ServicesIcon} from './ServiceElement'
import{NavBtn,NavBtnLink} from '../Topics/Topicele'
import { Grid } from '@material-ui/core';

var i = 0;
function change() {
  var doc = document.getElementById("ground");
  var color = ["#0075B4", "#4f72a5", "#9d6e97", "#EE6A88"];
  if (doc){
  doc.style.backgroundColor = color[i];
  i = (i + 1) % color.length;}
}
setInterval(change, 1000);


const Services = () => {
    
  
    return (
        <ServicesContainer id="services">
            <ServicesWrapper1>
                    <NavBtnLink1 to="who"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            Who can apply?
                    </NavBtnLink1>
            
                    <NavBtnLink1 to="topics"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            Eligible digital solutions
                    </NavBtnLink1>
            
                    <NavBtnLink1 to="apply"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            activeClass="active">
                            How to apply?
                    </NavBtnLink1>
            
                    <NavBtnLink1 to="online"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            Ideathon
                    </NavBtnLink1>
            
                    <NavBtnLink1 to="timeline"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            Timeline
                    </NavBtnLink1>
                    <NavBtnLink10 to="/FAQ">
                            FAQ
                    </NavBtnLink10>
                    <NavBtnLink2 id="ground" to="win"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            “Hack to act” Award
                    </NavBtnLink2>
        </ServicesWrapper1>
        <ServicesWrapper>
                <ServicesCard>
                    <ServicesH1>Build Your Capacities!</ServicesH1><br></br>
                    <ServicesP>Get trained and coached by our mentors on design thinking, digital rights and democracy and refine your project idea!</ServicesP>
                    <ServicesIcon src={Icon1}/>
                </ServicesCard>
                <ServicesCard1>
                    <ServicesH1>Learn Something New From Your Peers!</ServicesH1><br></br>
                    <ServicesP>Exchange with <b>45 people</b> <b>from 10 countries</b> and different backgrounds. Choose your own mentor and constitute your hack team!</ServicesP>
                    <ServicesIcon src={Icon2}/>
                </ServicesCard1>
                <ServicesCard2>
                    <ServicesH1>Get The Award And Let The Adventure Begin!</ServicesH1><br></br>
                    <ServicesP>Vote for your favourite peers! Pitch your solution and convince our mentors and your peers to vote for you! Let your digital tool be selected among the <b>3 best ones</b>  and <b><ServicesPf>GET AWARDED 10.000 €! </ServicesPf></b> </ServicesP>
                    <ServicesIcon src={Icon3}/>
                </ServicesCard2>
            </ServicesWrapper>
            <ServicesWrapper2>
            <NavBtn>
                        <NavBtnLink to="/Guidelines">
                                        Read the Guidelines for Applicants
                        </NavBtnLink>
            </NavBtn>
            </ServicesWrapper2>
        </ServicesContainer>
    )
}

export default Services
