import React, { useState, useEffect }from 'react'
import Icon1 from '../../images/Powerful.svg'
import Icon2 from '../../images/undraw_Social_networking_re_i1ex.svg'
import Icon3 from '../../images/undraw_winners_ao2o(1).svg'
import{ServicesContainer,NavBtnLink1,NavBtnLink10 ,NavBtnLink2,ServicesWrapper2,ServicesWrapper1, ServicesH1, ServicesCard,ServicesCard1,ServicesCard2, ServicesWrapper, ServicesP,ServicesPf, ServicesIcon} from '../Services/ServiceElement'
import{NavBtn,NavBtnLink} from '../Topics/Topicele'
import { Grid } from '@material-ui/core';

var i = 0;
function change() {
  var doc = document.getElementById("ground");
  var color = ["#0075B4", "#4f72a5", "#9d6e97", "#EE6A88"];
  if (doc){
  doc.style.backgroundColor = color[i];
  i = (i + 1) % color.length;}
}
setInterval(change, 1000);


const Services = () => {
    
  
    return (
        <ServicesContainer id="services">
            <ServicesWrapper1>
                    <NavBtnLink1 to="who"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            Qui peut y participer?
                    </NavBtnLink1>
            
                    <NavBtnLink1 to="topics"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            Solutions technologiques éligibles
                    </NavBtnLink1>
            
                    <NavBtnLink1 to="apply"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            activeClass="active">
                            Comment postuler?
                    </NavBtnLink1>
            
                    <NavBtnLink1 to="online"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            Ideathon
                    </NavBtnLink1>
            
                    <NavBtnLink1 to="timeline"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            Calendrier
                    </NavBtnLink1>
                    <NavBtnLink10 to="/FR/FAQ">
                           FAQ ‌
                    </NavBtnLink10>
                    <NavBtnLink2 id="ground" to="win"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            
                            activeClass="active">
                            Le Prix “Hack to act” 
                    </NavBtnLink2>
        </ServicesWrapper1>
        <ServicesWrapper>
                <ServicesCard>
                    <ServicesH1>Développez vos Capacités!</ServicesH1><br></br>
                    <ServicesP>Bénéficiez d’une formation et de sessions de coaching individuelles
                        fournies par nos mentors en Design Thinking, droits du cyberspace et
                        démocratie et affinez votre idée de solution technologique.
                    </ServicesP>
                    <ServicesIcon src={Icon1}/>
                </ServicesCard>
                <ServicesCard1>
                    <ServicesH1>Apprenez Quelque Chose de Nouveau de vos Pairs!</ServicesH1><br></br>
                    <ServicesP>Échangez avec <b>45 personnes</b> provenant <b>de 10 pays</b> différents et
                    d'horizons divers. Choisissez votre propre mentor et constituez votre équipe de hackeurs!
                    </ServicesP>
                    <ServicesIcon src={Icon2}/>
                </ServicesCard1>
                <ServicesCard2>
                    <ServicesH1>Soyez l’Heureux Gagnant et Que l’Aventure Commence !</ServicesH1><br></br>
                    <ServicesP>Votez pour vos pairs préférés ! Présentez votre solution devant le jury et
                    persuadez vos mentors et vos pairs de voter pour vous! Faites en sorte
                    que votre solution technologique soit sélectionnée parmi les <b>3 meilleures</b> et <ServicesPf>GAGNEZ les 10.000 € ! </ServicesPf> </ServicesP>
                    <ServicesIcon src={Icon3}/>
                </ServicesCard2>
            </ServicesWrapper>
            <ServicesWrapper2>
            <NavBtn>
                        <NavBtnLink to="/FR/Guidelines">
                                        Lire les lignes directrices des candidatures
                        </NavBtnLink>
            </NavBtn>
            </ServicesWrapper2>
        </ServicesContainer>
    )
}

export default Services
