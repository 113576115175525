import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {HeroH112} from '../HeroSection/HeroSectionElements';
import Header from './header';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '30px 0 30px 0',
    
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    textAlign:'center',
    alignContent:'center',
    display:'block',
    alignItems:'center'
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    textAlign:'center',
    padding:'5px'
  },
  paper:{
    backgroundColor:'#EE6A88',
    color:'#fff',
    textAlign:'center',
    alignContent:'center',
    alignItems:'center',
    "& .MuiAccordionSummary-content": { display: "block" }
  }
}));

const Faq = () => {
  const classes = useStyles();

  return (
    <>
        <Header/>
    <div className={classes.root}>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.paper}
        >
          <Typography className={classes.heading}>What is the “Digital civic space and the new technologies for democracy” Ideathon?</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify"}}>
            As a first step of the “Hack to act” Hackathon initiative, shortlisted CSO candidates who have applied with an idea for a digital solution, will participate in an online Ideathon and capacity building workshop:<b> “Digital civic space and the new technologies for democracy”</b> at the end of May 2021. During this workshop, participants will be trained and coached in order to further develop their project ideas before pitching them. Following a pitching session, <b>up to 15 digital solutions will be selected </b>to participate in the “Hack to act” Hackathon, in Tunis (Tunisia).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>What is the final Hackathon pitching session?</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify"}}>
            During the “Hack to act” Hackathon, up to 15 “Hack teams” will work and compete to present their projects to their peers and mentors. To this end, each “Hack team” will have to prepare a ready-to-go prototype of its solution in order to present its main functionalities to the audience and to give them a clear vision on how it will work. Following this pitching session, 3 digital solutions will be selected and awarded technical and financial support up to 10.000 € each.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>Do I need to be a member of a civil society organization to apply for the Hackathon with a digital solution idea?</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify"}}>
            No, you do not need to, but it is necessary to describe your background and experience as an active citizen engaged for democracy. This can consist in volunteering experience, taking part in your community life, student organisations, civic movements, media initiatives, political groups, and so on.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>I am a civil society activist, do I need to have a full Hack team including a designer and a developer, when applying with my Digital solution? If no, what about the constitution of Hack teams process?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{textAlign:"justify"}}>
            Each CSO activist must apply with his/ her own ITC tool/ Digital solution, so no applications from a “ready-to-use” Hack team will be required at this stage. However, a couple of online meetings will be dedicated to the constitution of Hack teams following the completion of the Ideathon. The constitution of Hack teams will happen in a participatory way: each CSO activist must convince developers and designers who already applied independently, to join his/her team (One developer + One designer). To this end, these online sessions will be an occasion for CSO activists to present individually their solutions and also for developers and designers to choose the solution in which they would be involved/ the team they want to belong (through an online speedy vote process).
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br></br>
<Typography className={classes.heading2}>If you have questions, please do not hesitate to write us at <b> hela.bensalah@particip.com </b>. We will post your question in this section.</Typography>
      
    </div>
    </>
  );
}

export default Faq
