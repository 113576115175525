import React, {useState} from 'react'
import Navbar from '../components/Navbar';
import Navbar2 from '../components/Navbar2';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import Services from '../components/Services';
import InfoSection from '../components/InfoSection1';
import InfoSection2 from '../components/InfoSection2';
import Roadmap from '../components/Roadmap';
import Topics from '../components/Topics';
import Shortlist from '../components/Shortlist';
import LastSec from '../components/LastSec';
import Sidebar from '../components/Sidebar';


const Landing = () => {
    const [isOpen, setisOpen] = useState(false)

    const toggle = () =>{
        setisOpen(!isOpen)
    }
    
    return (
        <>
            
            <Navbar toggle={toggle}/>
            <Navbar2/>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <HeroSection/>
            <Services />
            <InfoSection2/>
            <Topics/>
            <InfoSection/>
            <Shortlist/>
            <Roadmap/>
            <LastSec/>
            <Footer/>
        </>
    )
}

export default Landing
