import React, {useRef,useEffect,useState} from 'react';
import {HeroContainer11, HeroBg,ImgBg,Img,ImgWrap,Timer, TimerText,TimerWrapper1, TimerWrapper,StickyBarWrraper, StickyBar,StickyBarText, VideoBg, HeroContent, HeroH11, HeroH2, HeroP, HeroBtnWrapper, InfoRow, Column11, Column2,HeroH22 } from '../HeroSection/HeroSectionElements'
import {NavBtnLink12,NavBtn} from '../Navbar/NavbarElemnts'
import {TopLine,TextwMatfokch,TextwMatfokch1,ServicesWrapper111,NavBtnLink11, NavBtnLink20, NavBtnLink13} from '../Forms/Topicele'
const HeaderFR = () => {

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [timerDays1, setTimerDays1] = useState('00');
    const [timerHours1, setTimerHours1] = useState('00');
    const [timerMinutes1, setTimerMinutes1] = useState('00');
    const [timerSeconds1, setTimerSeconds1] = useState('00');
    let interval= useRef();

    const startTimer =() => {
        const countdownDate= new Date('May 16, 2021 00:00:00');

        interval=setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate- now;
            const days= Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours= Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000*  60));
            const seconds= Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                
            };
            
        }, 1000);
    };
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });

    return (
        <>
        <HeroContainer11 id="Hero">
            <HeroBg>
                <ImgBg src={require('../../images/herobackform1.png').default} alt={" Foire de Questions Hackathon"} />
            </HeroBg>
            <StickyBarWrraper>
            <StickyBar>
                <StickyBarText to='/FAQ'>EN</StickyBarText>
                <StickyBarText to='/FR/FAQ'>FR</StickyBarText>
                <StickyBarText to='/AR/FAQ'>عر</StickyBarText>
            </StickyBar>
            </StickyBarWrraper>
            <HeroContent>
                <InfoRow>
                <br></br>
                    <br></br>
                    <Column11>
                    
                    <HeroH11 to='/FR'>Hackathon <b>#HackToAct</b> </HeroH11>
                    <br></br>
                        <HeroH2>
                        <b>Foire aux questions</b>
                        </HeroH2>
                        
                    </Column11>
                   
                    
                </InfoRow>
                
            </HeroContent>
            
            <NavBtn>
                            <NavBtnLink12 to="/FR">
                                    Postulez maintenant!
                            </NavBtnLink12>
                    </NavBtn>
        </HeroContainer11>
        <ServicesWrapper111>
                <NavBtnLink13 to="/FR">
                        Hackathon #HackToAct  8 - 11 July 2021
                </NavBtnLink13>
                <NavBtnLink11 to="/FR/Guidelines">
                        Lire les lignes directrices des candidatures
                </NavBtnLink11>
                <NavBtnLink20 to="/FR/FormCivic">
                        Formulaire pour<br></br> les Activistes de la Société Civile
                </NavBtnLink20>
                <NavBtnLink20 to="/FR/FormTech">
                        Formulaire pour <br></br> les Développeurs IT & Graphistes
                </NavBtnLink20>
        </ServicesWrapper111>
        </>
    )
}

export default HeaderFR
