import React from 'react'
import {Button} from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2,ServicesPf, Column3, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, Img, ImgWrap } from './InfoElements'


const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, description2, buttonLabel,
     img, alt, primary, dark, dark2}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={"apply"}>
                <InfoWrapper>
                <Heading> How To Apply ? </Heading>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine> {topLine}</TopLine>
                                <Subtitle>If you are a <b>Civil Society Activist</b> with an idea for a <ServicesPf>digital solution / ICT tool,</ServicesPf> please apply before <b>22 April 2021</b> by filling in this form!</Subtitle>
                                <BtnWrap>
                                     <Button to='/FormCivic'
                                    >Apply as Civil Society Activist</Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={require('../../images/teamwork.svg').default} alt={"Apply as tech professional in the hackathon Tunisia"} />
                            </ImgWrap>
                        </Column2>
                        <Column3>
                            <TextWrapper>
                                <TopLine> {topLine}</TopLine>
                                <Subtitle darkText={darkText}>If you are a <b>Tech Professional or a Graphic or Web Designer,</b> please apply before <b>16 May 2021</b> by filling in this form!</Subtitle>
                                <BtnWrap>
                                    <Button to='/FormTech'
                                    >Apply as Tech Professional / Designer </Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
                
            </InfoContainer>
           </>
    )
}

export default InfoSection
