import styled from 'styled-components'
import {Link} from 'react-scroll'

export const FooterContainer = styled.div`
    background-color: #65A8C6;
    
`

export const FooterWrap= styled.div`
    font-family: "alwyn-new-rounded-web";
    Padding: 48 px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;

`

export const FooterLinksContainer= styled.div`
    display:flex;
    justify-content: center;

    @media screen and (max-width: 820px){
        padding-top:32px;
    }
`
export const FooterLinksWrapper= styled.div`
    display:flex;
    

    @media screen and (max-width: 820px){
        flex-direction: column ;
    }
`
export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column ;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width: 420x) {
        margin: 0;
        padding:10px;
        width: 100%;
    }
    `

export const FooterLinkTitle = styled.h1`
    font-size : 14px;
    margin-bottom: 16px;
    @media screen and (max-width: 820px){
        margin-top:20px;
    }
`

export const FooterLink= styled(Link)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover{
        color #01bf71;
        transition: 0.3s ease-out;
    }
`

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`

export  const SocialMediaWrap=styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 max-width: 1100px;
 margin: 40px auto 0 auto;
 
 @media screen and (max-width: 820px){
     flex-direction: column;
 }
`

export const SocialLogo = styled(Link)`
 color: #fff;
 justify-self: start;
 text-decoration: none;
 font-size: 1.5rem;
 display: flex;
 cursor: pointer;
 align-items: center;
 margin-bottom: 16px;
 font-weight: bold;
`

export const WebsiteRights= styled.small`
 color: #fff;
 margin-bottom: 16px;
 font-weight: 500;
 @media screen and (max-width: 820px){
    margin-top:20px;
}
`

export const SocialIcon = styled.div`
 display: flex;
 float:right;
 justify-content: space-between;
 align-items: center;
 width: 240px;
 @media screen and (max-width: 820px){
    float:none;
    margin-left:15px;
}
`

export const SocialIconLink = styled.a`
 color: #fff;
 font-size: 30px;
`

export const Logo=styled.div`
display: flex;
padding: 0 0px;
`;
export const NavLogo = styled(Link)`
    color:#393939;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 0;
    font-weight: bold;
    text-decoration: none;
`;

export const ServicesIcon= styled.img`
    margin-left: 10px;  
    margin-right: 10px;  
`

export const Text= styled.div`
    
    padding-top: 25px;
    padding-right: 25px;
    margin-left: 0px;
    font-size: 10px;
`
export const Title= styled.h2`
    font-weight: bold;
    font-size: 23px;
    line-height: 30px;
    color: #FFFFFF;
    margin-top: 50px;
    margin-right:-20px;
    margin-bottom: 60px;
    font-family: "alwyn-new-rounded-web",sans-serif;
    font-display: swap;
    @media screen and (max-width: 820px){
        
        margin: 50px 15px;
    }
`
export const Line= styled.div`
    border-left: 1px solid #393939;
    height: 82px;
    
`

export const List= styled.ul`
    display: block;
    list-style-type: none;
    padding-inline-start: 40px;
    text-decoration:none;
    color:#fff;
`
export const LiList= styled.li`
    color:#fff;
    margin-left:-40px;
    padding-top:10px;
    text-decoration:none;
    font-size: 12px;
    &:hover{
        color: #FFAA04;
        text-decoration: underline;
        transition: 0.6s ease-out;
    }
`
export const LihList= styled.li`
    color:#fff;
    margin-left:-40px;
    
`
export const Smalltitle= styled.h2`
    font-weight: bold;
    font-size: 15px;
    line-height: 30px;
    color: #FFFFFF;
    margin-top: 0px;
    font-family: "alwyn-new-rounded-web",sans-serif;
    font-display: swap;
    @media screen and (max-width: 820px){
        
        margin: 5px 15px;
    }
`

export const Smalltext= styled.p`
    
    font-weight: 500;
    font-size: 12px;
    @media screen and (max-width: 820px){
        margin: 0 15px;
    }
`

export const NavBtnLink1 = styled.button`
    font-family: 'alwyn-new-rounded-web',sans-serif;
    Border-Radius: 50px;
    align-items: center;
    white-space: nowrap;
    padding: 10px 44px;
    padding-right:20px;
    justify-content: right;
    cursor: pointer;
    border: 1px solid #66D0BA;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    outline: none;
    background: #076D97;
    font-weight: normal;
    font-size: 15px;
    color: #FFFFFF;
    height: 59.5px;
&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#3CBEFE;
    padding: 15px 44px;
    padding-right:20px;
    color: #fff;
}
`

export const Inp = styled.input`
    background:#fff;  
    position: relative;
    height: 60px;
    z-index:1;
    border-style:none;
    border-radius: 33px 0px 0px 33px;
    padding-left: 20px!important;
    border: none!important;
    height: 58px;
    line-height: 58px;
    background: #E5E5E5;
    font-weight: normal;
    font-size: 15px;
    color: #BBBBBB;
    width:250px;
    margin-right:-30px;
    padding-bottom:1px;
    outline: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
`;